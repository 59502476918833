import { TLocaleId } from 'src/i18n';
import { useContext } from 'react';
import NextGenScenarioContext from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/NextGenScenarioContext';
import { isCurrentUserSimSpec } from 'src/app/data/common/utils/userRoleUtils';

export enum GENERATION_TYPE {
  FIRST_GEN,
  NEXT_GEN,
}

export enum SCENARIO_MENUS_ITEM {
  SCENARIO_DETAILS = 'ScenarioDetails',
  EVENTS = 'Events',
  SCORING = 'Scoring',
  AVATAR_GUIDE = 'AvatarGuide',
  PLANNING = 'Planning',
  SCENARIO_REFERENCES = 'ScenarioReferences',
}
export enum SCENARIO_CHILD_MENUS_ITEM {
  SCENARIO_DETAILS_CONTENT = 'ScenarioDetailsContent',
  SCENARIO_DOCUMENTS = 'ScenarioDocuments',
  SCENARIO_DETAILS_LIBRARY_INFO = 'ScenarioDetailsLibraryInfo',
  SCENARIO_DELIVERY_DETAILS = 'ScenarioDeliveryDetails',
  SCENARIO_ASSIGNED_TEAMS= 'ScenarioAssignedTeams',
  EVENT_DETAILS = 'EventsDetails',
  SCORING_SYNOPSIS = 'ScoringSynopsis',
  SCORING_ASSESSMENT = 'ScoringAssessment',
  AVATAR_GUIDE_PERSPECTIVE = 'AvatarGuidePerspectives',
  AVATAR_GUIDE_PREP = 'AvatarGuidePrep',
  AVATAR_GUIDE_REFLECTION = 'AvatarGuideReflection',
  TIMELINE_AND_SCHEDULING_DETAILS = 'TimelineAndSchedulingDetails',
  CERTIFIED_SIMSPECIALIST = 'CertifiedSimSpecialist',
  INACTIVE_CERTIFICATIONS = 'InactiveCertifications',
  IN_TRAINING_SIMSPECIALIST = 'InTrainingSimSpecialist',
  CHILD_SCENARIO = 'ChildScenarios',
  CLIENT_CUSTOMIZATIONS = 'ClientCustomizations',
}

// enum to map api response fields with next gen side menu items
export enum NEXT_GEN_SIDE_MENU_ITEMS {
  ScenarioDetails = 'scenarioDetails',
  Events = 'events',
  Scoring = 'scoring',
  AvatarGuide = 'avatarGuide',
  TimelineAndScheduling = 'timelineAndScheduling',
  Planning = 'planning',
}

// enum to map api response fields with next gen side menu child items
export enum NEXT_GEN_SIDE_MENU_CHILD_ITEMS {
  ScenarioDetailsContent = 'content',
  ScenarioDetailsLibraryInfo = 'libraryInfo',
  ScenarioDeliveryDetails = 'deliveryDetails',
  ScenarioAssignedTeams = 'assignedTeams',
  ScenarioDocuments = 'scenarioDocuments',
  EventsDetails = 'eventDetails',
  ScoringSynopsis = 'synopsis',
  ScoringAssessment = 'assessment',
  AvatarGuidePerspectives = 'avatarPerspectives',
  AvatarGuidePrep = 'prepGuide',
  AvatarGuideReflection = 'reflectionGuide',
  TimelineAndSchedulingDetails = 'timelineAndScheduling',
  CertifiedSimSpecialist = 'certifiedSS',
  InactiveCertifications = 'inactiveSS',
  InTrainingSimSpecialist = 'practiceSS',
}

export enum mappingChildToParent {
  ScenarioDetailsContent = SCENARIO_MENUS_ITEM.SCENARIO_DETAILS,
  ScenarioDetailsLibraryInfo = SCENARIO_MENUS_ITEM.SCENARIO_DETAILS,
  ScenarioDeliveryDetails = SCENARIO_MENUS_ITEM.SCENARIO_DETAILS,
  EventsDetails = SCENARIO_MENUS_ITEM.EVENTS,
  ScoringSynopsis = SCENARIO_MENUS_ITEM.SCORING,
  ScoringAssessment = SCENARIO_MENUS_ITEM.SCORING,
  AvatarGuidePerspectives = SCENARIO_MENUS_ITEM.AVATAR_GUIDE,
  AvatarGuidePrep = SCENARIO_MENUS_ITEM.AVATAR_GUIDE,
  AvatarGuideReflection = SCENARIO_MENUS_ITEM.AVATAR_GUIDE,
  TimelineAndScheduling = SCENARIO_MENUS_ITEM.PLANNING,
  ScenarioAssignedTeams = SCENARIO_MENUS_ITEM.PLANNING,
  CertifiedSimSpecialist = SCENARIO_MENUS_ITEM.PLANNING,
  InactiveCertifications = SCENARIO_MENUS_ITEM.PLANNING,
  InTrainingSimSpecialist = SCENARIO_MENUS_ITEM.PLANNING,
  ChildScenarios = SCENARIO_MENUS_ITEM.SCENARIO_REFERENCES,
  CustomizedScenarios = SCENARIO_MENUS_ITEM.SCENARIO_REFERENCES,
}
interface IDefaultSideMenuItem {
  id: string;
  label: TLocaleId;
  showSubTab?: boolean;
}
interface IDefaultSideMenu {
  id: string;
  label: TLocaleId;
  header: TLocaleId;
  items: IDefaultSideMenuItem[];
  showTab?: boolean;
}

function nextGensideMenuConfig(): IDefaultSideMenu[] {
  const scenarioProps = useContext(NextGenScenarioContext);
  const { projectId, canViewTeam, userRole, scenarioContextDataState, isPracticeSessionEnabled } = scenarioProps;
  const { originId } = scenarioContextDataState.scenarioContextData;
  const isShowClientCustomizationTab = !!originId;
  const isShowTab = !projectId;
  const isShowTeamsCertifiedOrInactiveSimTab = projectId ? !!scenarioProps.certificateId : true;
  const isShowTeamTab = canViewTeam ? canViewTeam : !isShowTeamsCertifiedOrInactiveSimTab;
  const isHideScenarioRefreneceTab =  isCurrentUserSimSpec(userRole) || !!projectId;
  const isShowTrainingSessionTab = isPracticeSessionEnabled ? isShowTeamsCertifiedOrInactiveSimTab : false;

  return [
    {
      id: SCENARIO_MENUS_ITEM.SCENARIO_DETAILS,
      label: 'MursionPortal.SessionFlow.Label.ScenarioDetails',
      header: 'MursionPortal.SessionFlow.Label.ScenarioDetails',
      items: [
        {
          id: SCENARIO_CHILD_MENUS_ITEM.SCENARIO_DETAILS_CONTENT,
          label: 'MursionPortal.ScenarioBank.Content',
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.SCENARIO_DOCUMENTS,
          label: 'MursionPortal.ScenarioInfo.Documents',
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.SCENARIO_DETAILS_LIBRARY_INFO,
          label: 'MursionPortal.NextGen.Scenario.LibraryInfo',
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.SCENARIO_DELIVERY_DETAILS,
          label: 'MursionPortal.DeliveryDetails',
        },
      ],
    },
    {
      id: SCENARIO_MENUS_ITEM.EVENTS,
      label: 'MursionPortal.Header.Events',
      header: 'MursionPortal.Header.Events',
      items: [
        {
          id: SCENARIO_CHILD_MENUS_ITEM.EVENT_DETAILS,
          label: 'MursionPortal.ScenarioBank.EventDetail.Details',
        },
      ],
    },
    {
      id: SCENARIO_MENUS_ITEM.SCORING,
      label: 'MursionPortal.NextGen.Scenario.Scoring',
      header: 'MursionPortal.NextGen.Scenario.Scoring',
      items: [
        {
          id: SCENARIO_CHILD_MENUS_ITEM.SCORING_SYNOPSIS,
          label: 'MursionPortal.NextGen.Scenario.Synopsis',
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.SCORING_ASSESSMENT,
          label: 'MursionPortal.NextGen.Scenario.Assessment',
        },
      ],
    },
    {
      id: SCENARIO_MENUS_ITEM.AVATAR_GUIDE,
      label: 'MursionPortal.ScenarioBank.NextGen.AvatarGuide',
      header: 'MursionPortal.ScenarioBank.NextGen.AvatarGuide',
      items: [
        {
          id: SCENARIO_CHILD_MENUS_ITEM.AVATAR_GUIDE_PERSPECTIVE,
          label: 'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives',
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.AVATAR_GUIDE_PREP,
          label: 'MursionPortal.SimMaterials.Label.IntroAndPreReflection',
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.AVATAR_GUIDE_REFLECTION,
          label: 'MursionPortal.NextGen.Scenario.ReflectionGuide',
        },
      ],
    },
    {
      id: SCENARIO_MENUS_ITEM.PLANNING,
      label: 'MursionPortal.NextGen.Scenario.Planning',
      header: 'MursionPortal.NextGen.Scenario.Planning',
      items: [
        {
          id: SCENARIO_CHILD_MENUS_ITEM.CLIENT_CUSTOMIZATIONS,
          label: 'MursionPortal.NextGen.Scenario.ClientCustomizations',
          showSubTab: !isShowClientCustomizationTab,
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.TIMELINE_AND_SCHEDULING_DETAILS,
          label: 'MursionPortal.NextGen.Scenario.TimelineAndScheduling',
          showSubTab:isShowTab,
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.SCENARIO_ASSIGNED_TEAMS,
          label: 'Dashboard.Learners.Column.AssignedTeams',
          showSubTab: isShowTeamTab,
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.CERTIFIED_SIMSPECIALIST,
          label: 'Settings.ScenarioBank.Modal.CertifiedSimSpecialists',
          showSubTab: !isShowTeamsCertifiedOrInactiveSimTab,
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.INACTIVE_CERTIFICATIONS,
          label: 'MursionPortal.Label.InactiveSIMCertification',
          showSubTab: !isShowTeamsCertifiedOrInactiveSimTab,
        },
        {
          id: SCENARIO_CHILD_MENUS_ITEM.IN_TRAINING_SIMSPECIALIST,
          label: 'MursionPortal.Scenario.InTrainingSimSpecialist',
          showSubTab: !isShowTrainingSessionTab,
        },
      ],
    },
    {
      id: SCENARIO_MENUS_ITEM.SCENARIO_REFERENCES,
      label: 'MursionPortal.NextGen.ScenarioReferences',
      header: 'MursionPortal.ScenarioBank.NextGen.ViewScenario',
      showTab: isHideScenarioRefreneceTab,
      items: [
        {
          id: SCENARIO_CHILD_MENUS_ITEM.CHILD_SCENARIO,
          label: 'MursionPortal.NextGen.ChildScenarios',
          showSubTab: isHideScenarioRefreneceTab,
        },
      ],
    },
  ];
}
export default nextGensideMenuConfig;
