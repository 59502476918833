export enum ITEM_BANK_PAGE {
  SKILL = 'SKILL',
  EVENT = 'EVENT',
  DOMAIN = 'DOMAIN',
  PATHWAY = 'PATHWAY',
  RESOURCE = 'RESOURCE',
  OUTCOME = 'OUTCOME',
  AVATAR = 'AVATAR',
  MINDSET = 'MINDSET',
  RESEARCH_SUMMARY = 'RESEARCH_SUMMARY',
  LEARNER_INSTRUCTION = 'LEARNER_INSTRUCTION'
}