exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SurveyResponse__surveySubmitMsg___YKBYJ{margin:auto;text-align:center}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/layouts/unauthorized/SurveyResponse/SurveyResponse.css"],"names":[],"mappings":"AAAA,yCACI,YAAa,AACb,iBAAmB,CACtB","file":"SurveyResponse.css","sourcesContent":[".surveySubmitMsg {\n    margin: auto;\n    text-align: center;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"surveySubmitMsg": "SurveyResponse__surveySubmitMsg___YKBYJ"
};