import React, { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import EntitySelector, { ValueToOptionMapper } from 'src/components/EntitySelectors/EntitySelectorBase/EntitySelector';
import { SelectorTheme } from 'src/components/Selector/themes';
import { TLocaleId } from 'src/i18n';
import { getOptionValue } from 'src/app/data/common/utils';

export enum LevelType {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
}

type Value = LevelType[] | LevelType | null;
export type ILevelOnChange = (value: Value) => void;

export interface ILevelSelectorProps {
  onChange: ILevelOnChange;
  viewMode: boolean;
  placeholder: string;
  isMulti: boolean;
  themeType?: SelectorTheme;
  isSearchable?: boolean;
  value?: Value;
  searchPlaceholder?: string;
  label?: string;
  className?:string;
}
const levels = [LevelType.L1, LevelType.L2, LevelType.L3];

const levelLabel: { [key in LevelType]: TLocaleId } = {
  [LevelType.L1]: 'MursionPortal.ScenarioBank.ItemBank.Level.L1',
  [LevelType.L2]: 'MursionPortal.ScenarioBank.ItemBank.Level.L2',
  [LevelType.L3]: 'MursionPortal.ScenarioBank.ItemBank.Level.L3',
};

const LevelSelector: FunctionComponent<ILevelSelectorProps> = (props) => {
  const intl = useIntl();
  const { viewMode, value, isSearchable = true, themeType = SelectorTheme.Cobalt, label } = props;
  const levelSelector = useMemo(() => {
    const valueToOption: ValueToOptionMapper<LevelType, LevelType> = (level: LevelType) => ({
      value: level,
      label: intl.formatMessage({ id: levelLabel[level] }),
    });

    return (
      <>
        <EntitySelector 
          {...props} 
          items={levels} 
          isSearchable={isSearchable} 
          themeType={themeType}  
          valueToOption={valueToOption}
          optionToValue={getOptionValue} />
      </>
    );
  }, [levels, props]);

  return (
    <>
      {label && (
        <label>
          {label}
          {!viewMode && <>*</>}
        </label>
      )}
      {viewMode ? value : (levelSelector)}
    </>
  );
};

export default LevelSelector;
