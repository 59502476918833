import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styles from 'src/layouts/mursion/ScenarioBankContainer/components/common/TextEditingControl/TextEditingControl.css';
import { VIEW_MORE_BUILDING_BLOCK_CONTENT_LIMIT } from 'src/app/data/common/constants';
import ViewMore from 'src/layouts/common/Projects/components/ProjectCard/components/common/ScenarioEditForm/ViewMore';
import { customToolbarOptions } from 'src/components/TinyMceEditor/TimyMceEditor';
import csvInjectionProtector from 'src/app/data/common/utils/csvInjectionProtector';
import { TLocaleId } from 'src/i18n';
import cn from 'classnames';
import { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';
import InfoTooltip from 'src/components/InfoTooltip/InfoTooltip';
import { FormControl } from 'react-bootstrap';
import ErrorMsgContainer from 'src/components/ErrorMsgContainer/ErrorMsgContainer';
import RTFContainer from 'src/components/RTFContainer/RTFContainer';

export interface ITextEditingControlProps {
  label: TLocaleId | null;
  maxLength: number;
  onValueChange: (value: string, limitExceeded: boolean, isInputValid?: boolean, i?: number) => void;
  viewMode: boolean;
  value?: string;
  editorHeight: number;
  required?: boolean;
  index?: number;
  hasCharacterLimitLabel?: boolean;
  className?:string;
  tooltipMessage?: TLocaleId;
  showPlainTextEditor?: boolean;
  showErrorMsg?: boolean;
}

export interface ITextControl {
  text: string;
  limitExceeded: boolean;
  valid?: boolean;
}

const TextEditingControl: FunctionComponent<ITextEditingControlProps> = (props) => {
  const { label, maxLength, onValueChange, viewMode, value, editorHeight, required, index, hasCharacterLimitLabel = false, className, tooltipMessage, showPlainTextEditor, showErrorMsg = false } = props;
  const intl = useIntl();
  const [isLimitExceeded, setLimit] = useState<boolean>(false);
  const [length, setLength] = useState<number>(0);
  const [isInputTextValid , setIsInputTextValid] = useState<boolean>(true);

  useEffect(() => {
    if (viewMode && !isInputTextValid) {
      setIsInputTextValid(true);
    }
  }, [viewMode]);

  const onChange = (text: string, textLength: number, isInputValid: boolean) => {
    const limitExceed = maxLength ? textLength > maxLength : false;
    setLimit(limitExceed);
    setLength(textLength);
    setIsInputTextValid(isInputValid);
    onValueChange(csvInjectionProtector(text), limitExceed, isInputValid, index);
  };

  const onChangePlainText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const plainText = event.target.value;
    const valueLength = plainText.length;
    const limitExceeded = maxLength ? valueLength > maxLength : false;
    setLimit(limitExceeded);
    setLength(valueLength);
    setIsInputTextValid(valueLength>0);
    onValueChange(csvInjectionProtector(plainText), limitExceeded, true, index);
  };

  const renderErrorMsgContainer = () => {
    return (
      <ErrorMsgContainer
        errorMsg={'MursionPortal.Scenario.Error.Msg'}
        showErrorMsg={showErrorMsg}
        isInputValid={isInputTextValid}
      />
    );
  };

  const editorContainer = useMemo(() => {
    const commonEditorProps = {
      width: '100%',
      height:editorHeight ,
      onChange,
      value: value || '',
      placeholder: intl.formatMessage({ id: 'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder' }),
    };
    
    return (
      <div className={cn(styles.textEditor, isLimitExceeded && styles.editorLimitExceed)}>
        {
          showPlainTextEditor ?
            <FormControl
              className={styles.editTitleField}
              value={value}
              onChange={onChangePlainText}
              placeholder={intl.formatMessage({ id: 'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder' })}
            />
            :
            <RTFContainer
              tinyMceEditorProps={{
                ...commonEditorProps,
                menubar: false,
                statusbar: true,
                elementpath: false,
                toolbar: customToolbarOptions,
              }}
              froalaEditorProps={{
                ...commonEditorProps,
              }}
            />
        }  
        {renderErrorMsgContainer()}    
      </div>
    );
  }, [value, onChange, customToolbarOptions]);

  const renderCharacterAddedCount = () => {
    return isLimitExceeded ? (
      <div className={styles.maxLimit}>
        {intl.formatMessage(
          { id: 'MursionPortal.Scenario.AddedCharacter.Message' },
          {
            added: intl.formatNumber(length),
            totalChar: maxLength,
          }
        )}
      </div>
    ) : null;
  };

  const renderLimitExceedError = () => {
    return isLimitExceeded && maxLength ? (
      <div className={styles.error}>
        {intl.formatMessage(
          { id: 'MursionPortal.Scenario.CharacterLimitExceeded.Message' },
          { exceededCharLength: intl.formatNumber(length - maxLength) }
        )}
      </div>
    ) : null;
  };

  const showCharacterLimitLabel =() =>{
    if(!hasCharacterLimitLabel){
      return (
        <div className={styles.maxText}>
            {intl.formatMessage({ id: 'MursionPortal.Scenario.CharacterLimit.Message' }, { maxLength })}
          </div>
      );
    }
    return null;
  };
  return (
    <div className={cn(className, viewMode && styles.editorPreview)}>
      <div className={cn(styles.header, 'bankTitle')}>
        {
          label && <label>{intl.formatMessage({ id: label })}{required && !viewMode && <>*</>}
            {tooltipMessage &&
              <InfoTooltip
                ariaLabel={label}
                text={intl.formatMessage({ id: tooltipMessage })}
                placement={TooltipPlacement.TOP}
                theme={TooltipTheme.COBALT}
                classNames={styles.cobaltInfoBtn}
              />
            }
          </label>
        }
        {!viewMode && showCharacterLimitLabel()}
      </div>
      {viewMode ? (
        <ViewMore
          text={value || intl.formatMessage({ id: 'Settings.ScenarioBank.Modal.NoDescriptionSpecified' })}
          maxVisibleLength={VIEW_MORE_BUILDING_BLOCK_CONTENT_LIMIT}
          className={styles.vignetteField}
          isTextWithHtml={true}
        />
      ) : (
        <>
          {editorContainer}
          <div className={cn(styles.editorMeta, isLimitExceeded && styles.error)}>
            {renderLimitExceedError()}
            {renderCharacterAddedCount()}
          </div>
        </>
      )}
    </div>
  );
};

export default TextEditingControl;