import { IStickyColumns } from 'src/layouts/common/Dashboard/components/layout';
import { TLocaleId } from 'src/i18n';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { IDashboardSortingOrder } from 'src/app/data/dashboard/IDashboardConfig';
import { ITEM_BANK_PAGE } from 'src/layouts/mursion/ScenarioBankContainer/scenarioBankPageId';
import { IItemBankQueryBasic, IItemBankResponseItem, IResourcePageQuery, ISkillPageQuery } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/ItemBankList';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { ITEM_BANK_COLUMN_ID } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/table/ItemBankTable';
import { ComponentType } from 'react';
import { IResourceUpdate , IItemBankCreateBasic, ISkillCreate } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankCreate/ItemBankCreate';
import { ITEM_BANK_TABLE_FILTER } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';
import { IItemBank, IItemBankUpdate } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankDetails/ItemBankDetails';

export enum ITEM_BANK_CONTROLS {
  // Common columns
  TITLE,
  DESCRIPTION,
  // Skills
  LEVEL,
  DOMAIN,
  // Mindsets
  MINDSET_TYPE,
  // Resources  for research summary
  RESOURCES_SELECTOR,
  // Resource
  RESOURCE_TYPE,
  PUBLISHER,
  LINK,
  DATE,
  // Outcome
  OUTCOME_LEVEL,
}
export interface IItemBankDataList<T = string> {
  fetchAction: (query: { itemBankQuery: IItemBankQueryBasic | IResourcePageQuery | ISkillPageQuery }) => Promise<IListDataResponse<IItemBankResponseItem>>;
  columns: ITEM_BANK_COLUMN_ID[];
  stickyColumns?: IStickyColumns;
  filters: ITEM_BANK_TABLE_FILTER[];
  getDefaultSorting?: (roleId?: RoleID) => string;
  getDefaultSortByOrder?: (roleId?: RoleID) => IDashboardSortingOrder;
  createButtonText: TLocaleId;
  canCreateItem: boolean;
}

export interface IItemBankDataCreate {
  controls: ITEM_BANK_CONTROLS[];
  maxLength?: IBankDataItemMaxLength;
  createAction: (buildingBlockData: IResourceUpdate | IItemBankCreateBasic | ISkillCreate) =>  Promise<void>;
}

export interface IItemBankDataUpdate {
  controls: ITEM_BANK_CONTROLS[];
  headerTitle: TLocaleId;
  canEditItem: boolean;
  updateAction: (buildingBlockData: IResourceUpdate | IItemBankUpdate) =>  Promise<IItemBank>;
  fetchItemBankDetail: (id: string) =>  Promise<IItemBank>;
  archiveButtonTitle: TLocaleId;
  canArchiveItem: boolean;
}

export interface IBankDataItemMaxLength {
 titleLength?: number;
 descriptionLength?: number;
}

export interface IItemBankData {
  create: IItemBankDataCreate;
  edit: IItemBankDataUpdate;
  list: IItemBankDataList;
}
 interface IBankDataItem {
  data?: IItemBankData;
}
export interface INextGenConfig extends IBankDataItem {
  id?: ITEM_BANK_PAGE;
  label: TLocaleId;
  path: string;
  route: string;
  component?: ComponentType<IBankDataItem | any>;
  items?: IItemBankConfig[];
  disabled?: boolean;
}


export interface IItemBankConfig extends IBankDataItem {
  id: ITEM_BANK_PAGE;
  label: TLocaleId;
  path: string;
  route: string;
  disabled?: boolean;
}
