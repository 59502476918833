import moment from 'moment';
import { LevelType } from 'src/layouts/mursion/ScenarioBankContainer/components/common/Level/LevelSelector';
import { OutcomeLevelType } from 'src/layouts/mursion/ScenarioBankContainer/components/common/OutcomeLevelTypeSelector/OutcomeLevelSelector';
import { ITEM_BANK_PAGE } from 'src/layouts/mursion/ScenarioBankContainer/scenarioBankPageId';
import { IResourcesItems, ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import stripHtml from 'src/app/data/common/utils/stripHtml';
import { IFilterItem } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';
import { SelectorType, Value } from 'src/layouts/mursion/ScenarioBankContainer/components/common/Domain/DomainSelector';
import { ResourceStatusType } from 'src/layouts/mursion/ScenarioBankContainer/components/common/ResourceStatusTypeSelector/ResourceStatusTypeSelector';
import { MindSetType } from 'src/layouts/mursion/ScenarioBankContainer/components/common/MindsetTypeSelector/MindsetTypeSelector';
import { ITitleAndDescriptionValues } from 'src/layouts/mursion/ScenarioBankContainer/components/common/TitleAndDescriptionControl/TitleAndDescriptionControl';
import { AvatarRestrictionType } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioAvatarRestriction/NextGenScenarioAvatarRestriction';
import { isCurrentUserPSorOps, isCurrentUserSimDesigner, isCurrentUserSimSpec } from 'src/app/data/common/utils/userRoleUtils';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { ISkills } from 'src/app/data/nextGenScenario/interfaces/INextGenScenario';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';
import { intl } from 'src/i18n/createIntl';
import { BuildingBlockIteam } from 'src/layouts/mursion/ScenarioBankContainer/components/common/BuildingBlockReferencesSection/ReferencesSection';
import { IReasearchSummaryList, IScenarioList, ISkillsMappedToDomain } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankDetails/ItemBankDetails';
import { IItemBankControls } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankCreate/ItemBankCreate';
import ROUTE_PATHS from 'src/routing/paths';

export function getMaxLength(maxLength: number | undefined, itemMaxLength: number) {
    return maxLength ?? itemMaxLength;
}

export function formatDateForBackend(selectedYear: Date | string, selectedMonth: string = ''){
    let year = selectedYear;
    if(selectedYear instanceof Date){
      year = selectedYear.getFullYear().toString();
    }
    return year + selectedMonth;
}

export function extractYearValue(publishDate: string | undefined){
  if(!publishDate){
     return null;
  }
  const Year =  publishDate.slice(0, 4);
  return Year ? new Date(parseInt(Year, 10), 0, 1) : null;
}

export function getDate(yearValue: string | Date, month?: string){
  const year =  moment(yearValue, 'YYYY').format('YYYY');
  if(month){
     return  `${moment(month, 'MM').format('MMMM')} ${year}`;
  }
  return year;
}

export function extractMonthValue(publishDate?: string) {
  if (publishDate && publishDate?.length > 4) {
    return publishDate.slice(4,6);
  }
  return '';
}

export function getValue(value: string | undefined | null) {
  return value ?? '';
}

export function renderAsteriskSymbol(viewMode: boolean) {
  return viewMode ? '' : '*';
} 

export function canEditItems(canEditItem: boolean, viewMode: boolean, archived: boolean) {
  return canEditItem && viewMode && !archived;
}

export function fetchItemBankControlValue(item: LevelType | OutcomeLevelType | ResourceStatusType | MindSetType | null | undefined, itemBankPage: ITEM_BANK_PAGE) {
  switch (true) {
    case itemBankPage === ITEM_BANK_PAGE.SKILL:
      return item;
    case itemBankPage === ITEM_BANK_PAGE.OUTCOME:
      return item;
    case itemBankPage === ITEM_BANK_PAGE.RESOURCE:
      return item;
    case itemBankPage === ITEM_BANK_PAGE.MINDSET:
      return item;
    default:
      return null;
  }
}

export function filterSelectedResourcesInfo(resourcesData: IResourcesItems[]){
  return resourcesData.map(resources => resources.id);
}

export function filterResourceData(resourcesData: IFilterItem[] | null){
  if(!resourcesData){
    return [];
  }
  return resourcesData.map((resources: IFilterItem) => {
    return { id: resources.id, name: stripHtml(resources.title) };
  });
}

export function getLabelText(viewMode: boolean, labelText: string){
  return viewMode ?  '' : labelText;
}

export function getDomainValue(type: SelectorType, value:  Value | undefined, val: { id: string; name: string; } | null) {
  return type === SelectorType.FILTER ? value : val;
}

export function getArchiveItemBankUrl(type: ITEM_BANK_PAGE) {
  return type === ITEM_BANK_PAGE.RESOURCE ? 'nextGenScenario/buildingBlock/resource' : getArchivePathwayUrl(type);
}

function getArchivePathwayUrl(type: ITEM_BANK_PAGE) {
  return type === ITEM_BANK_PAGE.PATHWAY ? 'nextGenScenario/pathway' : 'nextGenScenario/buildingBlock';
}

export function getUnArchiveItemBankUrl(type: ITEM_BANK_PAGE) {
  return type === ITEM_BANK_PAGE.RESOURCE ? 'nextGenScenario/buildingBlock/resource/restore' : getUnArchivePathwayUrl(type);
}

function getUnArchivePathwayUrl(type: ITEM_BANK_PAGE) {
  return type === ITEM_BANK_PAGE.PATHWAY ? 'nextGenScenario/pathway/restore' : 'nextGenScenario/buildingBlock/restore';
}

export function filterScenarioContentData(scenarioContentData: ITitleAndDescriptionValues) {
  return { id: scenarioContentData.id ?? '', name: stripHtml(scenarioContentData.title || ''), description: scenarioContentData?.customDescription || scenarioContentData?.description || '' };
}

export function formatValues(values: AvatarRestrictionType[] | undefined | null){
  return values?.join(', ');
}

export function canEditCertifiedSSForNextGenScenarios(isArchived: boolean, scenarioStatus: ScenarioStatus, userRole: IUserRoleExtended | null): boolean {
  if (!userRole || !scenarioStatus || isArchived) {
    return false;
  }

  const allowedStatuses: ScenarioStatus[] = [
    ScenarioStatus.DRAFT,
    ScenarioStatus.STARTED,
    ScenarioStatus.UPCOMING
  ];

  return allowedStatuses.includes(scenarioStatus) && (isCurrentUserPSorOps(userRole) || isCurrentUserSimDesigner(userRole));
}

export function getButtonText(userRole: boolean, text: string) {
  return userRole ? text : '';
}

export function getSkillsData(skillsData: ISkills[]) {
  if (!skillsData?.length) {
    return [];
  }

  return skillsData.map((skills) => {
    return skills.buildingBlockWithCustomDetailsDTO;
  });
}

export function filterSkillsData(skillsData: IFilterItem[] | ISkills[]) {
  return getSkillsData(skillsData as ISkills[]);
}

export function getData(data: string) {
  return data.length ? getPlainText(data) : intl().formatMessage({ id: 'MursionPortal.Dashboard.Chart.Header.NoDataAvailable' });
}

export function getDescription(customDescription?: string | null, description?: string | null) {
  return customDescription || description || '';
}

export function getEntityData(type: ITEM_BANK_PAGE, buildingBlockIteamsList: BuildingBlockIteam) {
  switch (true) {
    case type === ITEM_BANK_PAGE.DOMAIN:
      const { skillId, skillTitle } = buildingBlockIteamsList as ISkillsMappedToDomain;
      return { title: skillTitle, titleLinkId: skillId };
    case type === ITEM_BANK_PAGE.RESOURCE:
      const { researchSummaryId, researchSummaryName } = buildingBlockIteamsList as IReasearchSummaryList;
      return { title: researchSummaryName, titleLinkId: researchSummaryId };
    default:
      const { scenarioName, scenarioId } = buildingBlockIteamsList as IScenarioList;
      return { title: scenarioName, titleLinkId: scenarioId };
  }
}

export function getBuildingBlockIteamsList(iItemBank: IItemBankControls) {
  return iItemBank.skillsMappedToDomain || iItemBank.scenarioList || iItemBank.reasearchSummaryList || [];
}

export function getLink(type: ITEM_BANK_PAGE, id: string) {
  switch (true) {
    case type === ITEM_BANK_PAGE.DOMAIN:
      return `${ROUTE_PATHS.SCENARIO_BANK}/skills/${id}`;
    case type === ITEM_BANK_PAGE.RESOURCE:
      return `${ROUTE_PATHS.SCENARIO_BANK}/research_summaries/${id}`;
    default:
      return `${ROUTE_PATHS.SCENARIO_BANK}/nextGenScenarios/${id}`;
  }
}

export function getReferencesSectionLabel(type: ITEM_BANK_PAGE) {
  switch (true) {
    case type === ITEM_BANK_PAGE.DOMAIN:
      return 'MursionPortal.SkillsReferences.Label';
    case type === ITEM_BANK_PAGE.RESOURCE:
      return 'MursionPortal.ResearchSummariesReferences.Label';
    default:
      return 'MursionPortal.NextGen.BankScenarioReferences';
  }
}

export function isShowReferencesSection(viewMode: boolean, type: ITEM_BANK_PAGE, userRole: IUserRoleExtended | null) {
  return viewMode && type !== ITEM_BANK_PAGE.PATHWAY && type !== ITEM_BANK_PAGE.MINDSET && !isCurrentUserSimSpec(userRole);
}