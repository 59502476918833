import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import styles from "./SurveyResponse.css";
import { useDispatch } from "react-redux";
import actions from "src/app/redux/store/actions";
import moment from "moment";
import getUrlParamValue from "src/app/data/common/utils/queryParamsAccessor";
import { useHistory } from "react-router";
import { useIntl } from 'react-intl';

const SurveyResponse: FunctionComponent<any> = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = history;

  const surveyId = getUrlParamValue("surveyId", location);
  const idempotencyKey = getUrlParamValue("idempotencyKey", location);
  const selectedOption = getUrlParamValue("selectedOption", location);
  const firstName = getUrlParamValue("firstName", location);
  const lastName = getUrlParamValue("lastName", location);
  const email = getUrlParamValue("email", location);
  const intl = useIntl();

  const reqBody = {
    values: {
      startDate: moment().format(),
      endDate: moment().format(),
      status: 1,
      progress: 100,
      finished: 1,
      recordedDate: moment().format(),
      distributionChannel: "email",
      userLanguage: "EN",
      QID1: parseInt(selectedOption,10),
      email_id: email,
      first_name: firstName,
      last_name: lastName,
      environment: new URL(process.env.PROXY_TARGET || '').host, 
    },
  };
  useEffect(() => {
    dispatch(actions.session.submitSessionSurveyResponse(surveyId, idempotencyKey, reqBody));
  }, []);

  return (
    <div className={styles.surveySubmitMsg}>
       <h1>{intl.formatMessage({ id:'MursionPortal.Survey.Thankyou.Msg'})}</h1>
    </div>
  );
};

export default SurveyResponse;