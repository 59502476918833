import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import IconButton from 'src/components/IconButton/IconButton';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';
import { toolbarOptions } from 'src/components/TinyMceEditor/TimyMceEditor';
import Tooltip, { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';
import cn from 'classnames';
import styles from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from 'src/app/redux';
import {
  IPersonalizationMsgCreate,
  PersonalizationMessageType,
} from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer';
import moment from 'moment-timezone';
import { getNearestAvailableTimezone } from 'src/app/data/common/utils/calendarUtils';
import getUserName from 'src/app/data/common/utils/getUserName';
import PersonalizationMessagesMetadata from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/PersonalizationMessagesMetadata/PersonalizationMessagesMetadata';
import IUser from 'src/app/data/common/interfaces/IUser';
import carretDown from 'src/images/caret-down.svg';
import { IScenarioShort } from 'src/app/data/projects/interfaces/IScenario';
import AssignItem, { AssignItemType } from 'src/components/AssignItem/AssignItem';
import assignTeamStyles from 'src/layouts/common/ClientUsers/components/NewClientUsers/components/AssignTeamsContainer/AssignTeamsContainer.css';
import Dropdown from 'react-bootstrap/Dropdown';
import ErrorMessage from 'src/components/ErrorMessage';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import Selector, { ISelectorOption } from 'src/components/Selector/Selector';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import actions from 'src/app/redux/store/actions';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import IProject, { IProjectShort } from 'src/app/data/projects/interfaces/IProject';
import { useParams } from 'react-router';
import RTFContainer from 'src/components/RTFContainer/RTFContainer';

interface IScenarioWithProjects {
  projectId: string;
  projectName: string;
  scenarioId?: string;
  scenarioName?: string;
}

interface IItem {
  id: string;
  name: string;
  nameCustomized?:string;
}

export interface ISelectedScenariosWithProject extends IItem {
  scenarios: IItem[];
}

export interface IPersonalizedMessage {
  id?: number; // optional in case of create new for specific scenario
  messageType: PersonalizationMessageType;
  message: string;
  operatedOn?: number;
  operatedBy?: IUser;
  messageIndex?: number;
  scenarios?: IScenarioWithProjects[];
  editMode?: boolean;
  projects?: IScenarioWithProjects[];
}

interface IPersonalizationCommunicationProps {
  headingText?: string;
  personalizedMessage: IPersonalizedMessage | null;
  personalizationMessageType: PersonalizationMessageType;
  onSave: (personalizationMsgData: IPersonalizationMsgCreate, personalizedMessageId: number | undefined, index: number | undefined, callback: (arg: boolean) => void) => void;
  maxLength?: number;
  index?: number;
  error: string;
  onCancel: (personalizationMessageType: PersonalizationMessageType) => void;
  onDelete?: (msgId: number) => void;
  onToggleChange: (mode: boolean, type: PersonalizationMessageType, index?: number) => void;
  canEditMessage: boolean;
  containerClass?: string;
}

export interface ISelectedPathways extends IItem {
  projects: IItem[];
  scenarios?: IItem[];
}

const PersonalizationCommunication: FunctionComponent<IPersonalizationCommunicationProps> = (props) => {
  const intl = useIntl();
  const { clientID } = useParams<{ clientID: string }>();
  const idForClientUsers = useSelector(selectors.profile.getClientId);
  const userProfile = useSelector(selectors.profile.getUserProfile);
  const dispatch = useDispatch();

  const clientId = idForClientUsers || clientID;

  const {
    headingText,
    maxLength,
    onSave,
    personalizedMessage,
    personalizationMessageType,
    index,
    error,
    onCancel,
    onDelete,
    onToggleChange,
    canEditMessage,
    containerClass,
  } = props;
  

  const [viewMode, setViewMode] = useState<boolean>(true);
  const [personalizedMessageValue, setPersonalizedMessageValue] = useState<string>('');
  const [personalizedMsgScenarios, setPersonalizedMessageScenarios] = useState<ISelectedScenariosWithProject[]>([]);
  const [disableSaveButton, setSaveButtonValue] = useState<boolean>(false);
  const [showMetaData, setShowMetadata] = useState<boolean>(false);
  const [isLimitExceeded, setLimit] = useState<boolean>(false);
  const [length, setLength] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [showDeleteMessageModal, setDeleteMessageModalValue] = useState<boolean>(false);
  const [showEditOneMsgModal, setEditOneMsgModalValue] = useState<boolean>(false);
  const [projects, setProjects] = useState<ISelectorOption[]>([]);
  const [scenarios, setScenarios] = useState<IScenarioShort[]>([]);
  const [selectedProject, setSelectedProject] = useState<ISelectorOption>();
  const [loading, setLoading] = useState(false);
  const [pathways, setPathways] = useState<IProjectShort[]>([]);
  const [personalizedMsgPathways, setPersonalizedMessagePathways] = useState<ISelectedPathways[]>([]);
  const [viewModePathways, setViewModePathways] = useState<boolean>(true);

  const TIMEZONE_ID: string = userProfile?.timezoneId || getNearestAvailableTimezone(moment.tz.guess()).id;

  useEffect(() => {
    setPersonalizedMessageScenarios(projectAndTheirSelectedScenarios());
  }, [personalizedMessage?.scenarios]);

  useEffect(() => {
    setPersonalizedMessagePathways(getPathways());
  }, [personalizedMessage?.projects]);
  
  useEffect(() => {
    if (personalizedMessage?.editMode) {
      setViewMode(false);
      setViewModePathways(false);
    }
  }, [personalizedMessage?.editMode]);

  useEffect(() => {
    if (personalizationMessageType === PersonalizationMessageType.SCENARIO_MESSAGE && !viewMode) {
      setProjects([]);
      setPathways([]);
      setScenarios([]);
      setLoading(true);
      dispatch(
        actions.clients.fetchClientProjects({
          page: 0,
          size: 9999,
          archive: 'active',
          status: 'active',
          clientId,
        })
      )
        .then((res: IProject[]) => {
          const options: ISelectorOption[] = res.map((project) => ({
            value: project.id,
            label: project.name,
          }));
          setProjects(options);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (personalizationMessageType === PersonalizationMessageType.PATHWAY_MESSAGE && !viewModePathways) {
      fetchPathwaySelection();
    }
  }, [personalizationMessageType, viewMode, viewModePathways]);

  const fetchPathwaySelection = () => {
    setLoading(true);
    dispatch(
      actions.scenarios.learners.getPersonalizedPathwayList(clientId)
    )
      .then((response: { projects: IProjectShort[] }) => {
        if (response) {
          setPathways(response.projects);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFilteredPathways = () => {
    return (
      pathways?.filter(
        (pathwayInfo) =>
          !personalizedMsgScenarios.some((pathway) => pathways.findIndex(sc => sc.id === pathway.id) > -1) &&
          (pathwayInfo.name || '').toLowerCase().indexOf(searchText.toLowerCase()) > -1
      ) || []
    );
  };

  useEffect(() => {
    setPersonalizedMessageValue(personalizedMessage?.message || '');
  }, [personalizedMessage?.message]);

  const onToggle = () => {
    if (viewMode && !canEditMessage) {
      setEditOneMsgModalValue(true);
      return;
    }
    setViewMode(!viewMode);
    setViewModePathways(!viewModePathways);
    if (isScenarioMessage()) {
      const disable = personalizedMessage?.message ? personalizedMessage.message.length <= 0 : true;
      setSaveButtonValue(disable);
    }
    onToggleChange(viewMode, personalizationMessageType, index);
    onToggleChange(viewModePathways, personalizationMessageType, index);
  };

  const onCancelBtnClick = () => {
    setViewMode(!viewMode);
    setViewModePathways(!viewModePathways);
    setLimit(false);
    setPersonalizedMessageScenarios(projectAndTheirSelectedScenarios());
    setPersonalizedMessagePathways(getPathways());
    setSaveButtonValue(false);
    if (error || personalizationMessageType === PersonalizationMessageType.SCENARIO_MESSAGE) {
      setSelectedProject(undefined);
      onCancel(personalizationMessageType);
    }
    onToggleChange(viewMode, personalizationMessageType, index);
    onToggleChange(viewModePathways, personalizationMessageType, index);
    setPersonalizedMessageValue(personalizedMessage?.message || '');
  };

  const projectAndTheirSelectedScenarios = (): ISelectedScenariosWithProject[] => {
    if (personalizedMessage?.scenarios) {
      const arr = personalizedMessage.scenarios.reduce((a, { projectId, projectName, scenarioId, scenarioName}) => {
        if (!a[projectId]) {
          a[projectId] = { id: projectId, name: projectName, scenarios: [] };
        }
        a[projectId].scenarios.push({ id: scenarioId, name: scenarioName});
        return a;
      }, {});
      return Object.values(arr);
    } else {
      return [];
    }
  };

  const getScenarioIds = () => {
    const ids: string[] = [];
    personalizedMsgScenarios.forEach((project) => {
      project.scenarios.forEach((sc) => {
        ids.push(sc.id);
      });
    });
    return ids;
  };

  const onSaveBtnClick = () => () => {
    let entityIdsVal = null;
    if (personalizationMessageType === PersonalizationMessageType.SCENARIO_MESSAGE && personalizedMsgScenarios.length) {
      entityIdsVal = getScenarioIds();
    } else if (personalizationMessageType === PersonalizationMessageType.PATHWAY_MESSAGE && personalizedMsgPathways.length) {
      entityIdsVal = getPathwayIds();
    }
    const PersonalizedMsgData = {
      clientId,
      message: personalizedMessageValue,
      messageType: personalizationMessageType,
      entityIds: entityIdsVal
    };

    onSave(PersonalizedMsgData, personalizedMessage?.id, index, (arg) => {
      if (!arg) {
        setViewMode(!viewMode);
        setViewModePathways(!viewModePathways);
      }
    });
  };

  const getPathways = (): ISelectedPathways[] => {
    if (personalizedMessage?.projects) {
      const arr = personalizedMessage.projects.reduce((a, { projectId, projectName }) => {
        if (!a[projectId]) {
          a[projectId] = { id: projectId, name: projectName };
        }
        return a;
      }, {});
      return Object.values(arr);
    } else {
      return [];
    }
  };

  const getPathwayIds = () => {
    const ids: string[] = [];
    personalizedMsgPathways.forEach((pathway) => {
      ids.push(pathway.id);
    });
    return ids;
  };

  const onChange = (value: string, textLength: number, isInputValid: boolean) => {
    const limit = maxLength ? textLength > maxLength : false;
    if (isScenarioMessage()) {
      setSaveButtonValue(!isInputValid || limit);
    } else {
      setSaveButtonValue(limit);
    }
    setLength(textLength);
    setLimit(limit);
    setPersonalizedMessageValue(value);
  };

  const getSubmitButtonTheme = () => {
    return disableSaveButton || ifNoScenarioSelectedToSave() ? ButtonType.LIGHT : ButtonType.BLUE;
  };

  const ifNoScenarioSelectedToSave = () => {
    if (personalizationMessageType === PersonalizationMessageType.SCENARIO_MESSAGE) {
      return !personalizedMsgScenarios.length || !personalizedMessageValue.length;
    } else if (personalizationMessageType === PersonalizationMessageType.PATHWAY_MESSAGE) {
      return !personalizedMsgPathways.length || !personalizedMessageValue.length;
    }
    return false;
  };

  const onDeleteButtonClick = () => {
    setDeleteMessageModalValue(true);
  };

  const onCloseModal = () => {
    setDeleteMessageModalValue(false);
  };

  const onConfirmDelete = () => {
    if (personalizedMessage?.id) {
      onCloseModal();
      if (onDelete) {
        onDelete(personalizedMessage?.id);
      }
    }
  };

  const isScenarioMessage = () => personalizationMessageType === PersonalizationMessageType.SCENARIO_MESSAGE;

  const renderCancelAndSaveBtn = () => {
    return (
      <div className={styles.buttonWrap}>
        <Button
          btnType={ButtonType.CANCEL}
          btnFont={ButtonFont.BOLD}
          btnSize={ButtonSize.MEDIUM}
          title={intl.formatMessage({ id: 'Users.Button.Cancel' })}
          arial-label={intl.formatMessage({ id: 'Users.Button.Cancel' })}
          onClick={onCancelBtnClick}
        >
          {intl.formatMessage({ id: 'Users.Button.Cancel' })}
        </Button>
        <Tooltip
          placement={TooltipPlacement.TOP}
          text={intl.formatMessage({ id: 'MursionPortal.Personalized.Section.Tooltip' })}
          theme={TooltipTheme.COBALT}
        >
          <Button
            btnType={getSubmitButtonTheme()}
            btnFont={ButtonFont.BOLD}
            btnSize={ButtonSize.MEDIUM}
            disabled={disableSaveButton || ifNoScenarioSelectedToSave()}
            title={intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
            arial-label={intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
            onClick={onSaveBtnClick()}
          >
            {intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
          </Button>
        </Tooltip>
      </div>
    );
  };

  const editorContainer = useMemo(() => {
    
    const commonEditorProps = {
      width: '100%',
      height: 230,
      onChange,
      value: personalizedMessage?.message || '',
      placeholder: '',
    };
    return (
      <RTFContainer
        tinyMceEditorProps={{
          ...commonEditorProps,
          menubar: false,
          statusbar: true,
          elementpath: false,
          toolbar: toolbarOptions,
        }}
        froalaEditorProps={{
          ...commonEditorProps,
        }}
      />
    );
  }, [personalizedMessage?.message, onChange, toolbarOptions]);
   
  const addItem = (id: string, name: string,nameCustomized:string) => () => {
    const scenarioData = [...personalizedMsgScenarios];
    if (selectedProject) {
      const projectIdx = scenarioData.findIndex((project) => project.id === selectedProject.value);
      if (projectIdx > -1) {
        scenarioData[projectIdx].scenarios.push({ id, name, nameCustomized});
      } else {
        scenarioData.push({
          id: selectedProject.value.toString(),
          name: selectedProject.label,
          scenarios: [{ id, name, nameCustomized }],
        });
      }
      setPersonalizedMessageScenarios(scenarioData);
    }
  };

  const removeItem = (id: string) => () => {
    let scenarioData = [...personalizedMsgScenarios];
    const projectIndex = scenarioData.findIndex((project) => project.scenarios.some((sc) => sc.id === id));
    if (scenarioData[projectIndex].scenarios.length > 1) {
      scenarioData[projectIndex].scenarios = scenarioData[projectIndex].scenarios.filter((sc) => sc.id !== id);
    } else {
      scenarioData = scenarioData.filter((project) => !project.scenarios.some((sc) => sc.id === id));
    }
    setPersonalizedMessageScenarios(scenarioData);
  };

  const onSearch = (text: string) => {
    setSearchText(text);
  };

  const getFilteredScenarios = () => {
    return (
      scenarios?.filter(
        (scenario) =>
          !personalizedMsgScenarios.some((project) => project.scenarios.findIndex(sc => sc.id === scenario.id) > -1) &&
          (scenario.name || '').toLowerCase().indexOf(searchText.toLowerCase()) > -1
      ) || []
    );
  };

  const addItemPathway = (id: string, name: string) => () => {
    const pathwayData = [...personalizedMsgPathways];
    pathwayData.push({
      id,
      name,
      projects: [{ id, name }],
    });
    const uniquepathwayDataById = pathwayData.reduce((acc, obj) => {
      if (!acc[obj.id]) {
        acc[obj.id] = obj;
      }
      return acc;
    }, {});
    const uniquepathwayData: ISelectedPathways[] = Object.values(uniquepathwayDataById);
    setPersonalizedMessagePathways(uniquepathwayData);
  };

  const renderProjectSelector = () => {
    return !viewMode ? (
      <div className={styles.projectDropDown}>
        <div className={styles.newScenariotitle}>{intl.formatMessage({ id: 'MursionPortal.Personalized.Scenario.Selection.Title' })}</div>
        <div className={styles.projectTitle}>{intl.formatMessage({ id: 'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder' })}</div>
        <Selector
          value={selectedProject}
          className={styles.selector}
          options={projects}
          onChange={handleProjectSelection}
          placeholder={intl.formatMessage({
            id: 'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder',
          })}
          ariaLabel={intl.formatMessage({ id: 'MursionPortal.Capitalized.Project' })}
          themeType={SelectorTheme.Blue}
        />
      </div>
    ) : null;
  };

  const renderPathwayOrScenarioSelector = () => {
    if (personalizationMessageType === PersonalizationMessageType.PATHWAY_MESSAGE) {
      return renderPathwaySelector();
    }
    return renderProjectAndScenarioSelector();
  };

  const renderPathwaySelector = () => {
    return <>
      <div className={styles.scenarioSelection}>
        <div className={assignTeamStyles.assignTeamUserWrap}>
          <div className={assignTeamStyles.assignTeamUserInner}>
            <AssignItem
              isItemUpdationNotAllowed={viewModePathways}
              title={''}
              buttonTitle={intl.formatMessage({ id: 'MursionPortal.Add' })}
              searchTitle={intl.formatMessage({ id: 'MursionPortal.Personalized.Pathway.Search.Placeholder' })}
              searchText={searchText}
              onSearch={onSearch}
              availableItems={pathways}
              restAvailableItems={getFilteredPathways()}
              add={addItemPathway}
              remove={removeItem}
              noItemAdded={''}
              noItemAvailableToAddText={intl.formatMessage({ id: 'Projects.ScenarioList.NoPathwaysFound' })}
              className={cn(styles.assignTeamsWrapper, containerClass)}
              type={AssignItemType.SUBTYPE}
              subTypedSelectedItems={personalizedMsgPathways}
            />
          </div>
        </div>
      </div>
    </>;
  };

  const renderProjectAndScenarioSelector = () => {
    return personalizationMessageType === PersonalizationMessageType.SCENARIO_MESSAGE ? (
      <>
        {renderProjectSelector()}
        <div className={styles.scenarioSelection}>
          <div className={assignTeamStyles.assignTeamUserWrap}>
            <div className={assignTeamStyles.assignTeamUserInner}>
              <AssignItem
                isItemUpdationNotAllowed={viewMode}
                title={''}
                buttonTitle={intl.formatMessage({ id: 'MursionPortal.Add' })}
                searchTitle={intl.formatMessage({ id: 'MursionPortal.Personalized.Scenario.Search.Placeholder' })}
                searchText={searchText}
                onSearch={onSearch}
                availableItems={scenarios}
                restAvailableItems={getFilteredScenarios()}
                add={addItem}
                remove={removeItem}
                noItemAdded={''}
                noItemAvailableToAddText={intl.formatMessage({ id: 'Projects.ScenarioList.NoScenariosFound' })}
                className={styles.assignTeamsWrapper}
                type={AssignItemType.SUBTYPE}
                subTypedSelectedItems={personalizedMsgScenarios}
              />
            </div>
          </div>
        </div>
      </>
    ) : null;
  };

  const handleProjectSelection = (project: ISelectorOption) => {
    setScenarios([]);
    setSelectedProject(project);
    setLoading(true);
    dispatch(actions.scenarios.learners.getPersonalizedScenarioList(clientId, project.value))
      .then((response: { scenarios: IScenarioShort[] }) => {
        if (response) {
          setScenarios(response.scenarios);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleMetaData = () => {
    setShowMetadata(!showMetaData);
  };

  const renderShowMetaDataIcon = () => {
    return <button className={styles.carret} onClick={toggleMetaData} aria-label={intl.formatMessage({ id: 'MursionPortal.Contract.Edit' })}><img src={carretDown} alt={intl.formatMessage({ id: 'MursionPortal.Contract.Edit' })} /></button>;
  };

  const renderPersonalizedMessage = () => {
    return personalizedMessage?.message ? getPlainText(personalizedMessage.message) : <div className={styles.itemPleaceholderText}>{intl.formatMessage({ id: 'MursionPortal.Personalized.NoMessage' })}</div>;
  };

  const getClassByValue = (value: boolean, className: string) => {
    return value ? className : '';
  };

  const onCancelOneScenarioMessageAtATimeModal = () => {
    setEditOneMsgModalValue(false);
  };

  const editOneScenarioMessageAtATimeModal = useMemo(() => {
    return (
      <ConfirmationModal
        show={showEditOneMsgModal}
        bodyText={intl.formatMessage({ id: 'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime' })}
        onCancelModal={onCancelOneScenarioMessageAtATimeModal}
        confirmationTitle={intl.formatMessage({ id: 'MursionPortal.Button.Exclamation.GotIt' })}
        buttonSize={ButtonSize.MEDIUM}
      />
    );
  }, [showEditOneMsgModal]);

  return (
    <LoadingOverlay active={loading} spinner={true} showFullScreenOverlay={true}>
      <div className={styles.PersonalizationItem}>
        <div className={styles.PersonalizationItemHeader}>
          {headingText}
          {viewMode ? (
            <div className={styles.PersonalizationIcon}>
              <IconButton
                iconName={'pencil-alt'}
                title={intl.formatMessage({ id: 'MursionPortal.Contract.Edit' })}
                onClick={onToggle}
                className={styles.editButton}
              />
              {!!onDelete && personalizedMessage?.id && <Tooltip
                placement={TooltipPlacement.TOP}
                text={intl.formatMessage({ id: 'MursionPortal.Personalized.Message.Delete.Tooltip' })}
                theme={TooltipTheme.COBALT}
                className={styles.deteleTooltip}
              >
                <div className={styles.deleteIcon}>
                  <IconButton
                    iconName={'undo'}
                    onClick={onDeleteButtonClick}
                    className={styles.editButton}
                  />
                </div>
              </Tooltip>}
            </div>
          ) : null}
        </div>
        <div className={cn(styles.PersonalizationItemBody, getClassByValue(!viewMode, styles.editMode))}>
          {renderPathwayOrScenarioSelector()}
          {viewMode ? (
            <div className={styles.PersonalizationItemBodyText}>
              {renderPersonalizedMessage()}
            </div>
          ) : (
            <>
              <div className={cn(styles.textEditor, getClassByValue(isLimitExceeded, styles.errorOnEdit))}>
                {maxLength && <div className={styles.maxLengthText}>{intl.formatMessage({ id: 'MursionPortal.Scenario.CharacterLimit.Message' }, { maxLength })}</div>}
                {editorContainer}
              </div>
              <div className={cn(styles.errorWrapper, getClassByValue(isLimitExceeded, styles.withErrorMessageWrapper))}>
                {isLimitExceeded && maxLength &&
                  <div className={styles.errorMessage}>
                    {intl.formatMessage({ id: 'MursionPortal.Scenario.CharacterLimitExceeded.Message' }, { exceededCharLength: intl.formatNumber(length - maxLength) })}
                  </div>
                }
                {isLimitExceeded && <div className={cn(styles.textLimit, getClassByValue(isLimitExceeded, styles.errorMessage))}>
                  {intl.formatMessage({ id: 'MursionPortal.Scenario.AddedCharacter.Message' }, {
                    added: intl.formatNumber(length),
                    totalChar: maxLength
                  })}
                </div>
                }
              </div>
              {error && <ErrorMessage message={error} />}
              {renderCancelAndSaveBtn()}
            </>
          )}
        </div>
        {viewMode && personalizedMessage?.operatedOn && personalizedMessage?.operatedBy && personalizedMessage?.id ? (
          <div className={cn(styles.lastUpdatedWrapper, getClassByValue(showMetaData, styles.openCard))}>
            <Dropdown show={showMetaData}
              onToggle={toggleMetaData}>
              <strong>{intl.formatMessage({ id: 'MursionPortal.Setting.Personalization.LastUpdated' })}</strong> {`${moment(personalizedMessage.operatedOn)
                .tz(TIMEZONE_ID)
                .format('lll')}, ${getUserName(personalizedMessage.operatedBy)}`}
              {renderShowMetaDataIcon()}
              <Dropdown.Menu className={styles.lastUpdatedContainer}>
                <PersonalizationMessagesMetadata
                  timezone={TIMEZONE_ID}
                  onClose={toggleMetaData}
                  id={personalizedMessage.id}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
      </div>
      <ConfirmationModal
        show={showDeleteMessageModal}
        bodyText={intl.formatMessage({ id: 'MursionPortal.Personalized.Message.Delete.Title' })}
        onCancelModal={onCloseModal}
        submitBtnTitle={intl.formatMessage({ id: 'MursionPortal.Label.Yes' })}
        onOtherAction={onCloseModal}
        onConfirm={onConfirmDelete}
      />
      {editOneScenarioMessageAtATimeModal}
    </LoadingOverlay>
  );
};

export default PersonalizationCommunication;
