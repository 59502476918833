import React, { FunctionComponent, useEffect, useState } from 'react';
import EntitySelector from 'src/components/EntitySelectors/EntitySelectorBase/EntitySelector';
import { SelectorTheme } from 'src/components/Selector/themes';
import actions from 'src/app/redux/store/actions';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';
import stripHtml from 'src/app/data/common/utils/stripHtml';
import { ITEM_BANK_PAGE } from 'src/layouts/mursion/ScenarioBankContainer/scenarioBankPageId';
import { getDomainValue } from 'src/layouts/mursion/ScenarioBankContainer/utils/itemBankUtils';
import { useDispatch } from 'react-redux';
import { IItemBankList } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';

export type Value = INamedEntry[] | INamedEntry | null;
export type IDomainOnChange = (value: Value) => void;

export interface IDomainSelectorProps {
  onChange: IDomainOnChange;
  viewMode: boolean;
  placeholder: string;
  isMulti: boolean;
  themeType?: SelectorTheme;
  isSearchable?: boolean;
  value?: Value;
  searchPlaceholder?: string;
  label?: string;
  className?:string;
  type?: SelectorType;
}

export enum SelectorType {
  SELECT,
  FILTER
}

const DomainSelector: FunctionComponent<IDomainSelectorProps> = (props) => {
  const {
    viewMode,
    value,
    isMulti = true,
    isSearchable = true,
    themeType = SelectorTheme.Cobalt,
    label,
    type = SelectorType.FILTER
  } = props;

  const dispatch = useDispatch();
  const [itemsInfo, setItemsInfo] = useState<IItemBankList[]>([]);
  
  useEffect(() => {
    const fetchDomainData = async () => {
      const response = await dispatch(actions.nextGenScenario.getAllActiveItemBankListByType(ITEM_BANK_PAGE.DOMAIN));
      setItemsInfo(response);
    };

    fetchDomainData();
  }, []);

  const formattedValueToDisplay: INamedEntry = value as INamedEntry;

  const val = formattedValueToDisplay ? { id: formattedValueToDisplay.id, name: stripHtml(formattedValueToDisplay.name) } : null;

  return (
    <>
      {label && (
        <label>
          {label}
          {!viewMode && <>*</>}
        </label>
      )}
      {viewMode ? (
       formattedValueToDisplay && getPlainText(formattedValueToDisplay.name)
      ) : (
        <EntitySelector
          {...props}
          value={getDomainValue(type, value, val)}
          isMulti={isMulti}
          isSearchable={isSearchable}
          themeType={themeType}
          items={itemsInfo}
        />
      )}
    </>
  );
};

export default DomainSelector;
