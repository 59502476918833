import LoadChunk from 'src/app/services/LoadChunk/LoadChunk';

const Companies = LoadChunk(() => import('./Companies'));
const Sessions = LoadChunk(() => import('./Sessions'));
const AssetSync = LoadChunk(() => import('./AssetSync'));
const GlobalUsers = LoadChunk(() => import('./Users'));
const TechRestrictions = LoadChunk(() => import('./TechRestrictions'));

export default {
  AssetSync,
  Companies,
  Sessions,
  GlobalUsers,
  TechRestrictions
};
