import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { MAX_PERSONALIZED_DASHBOARD_SECTION_LENGTH } from 'src/app/data/common/constants';
import {
  IPersonalizationMsgCreate,
  PersonalizationMessageType,
} from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer';
import PersonalizationCommunication, {
  IPersonalizedMessage,
} from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/PersonalizationCommunication';
import IconButton from 'src/components/IconButton/IconButton';
import styles from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/ScenarioPersonalizationCommunication/ScenarioPersonalizationCommunication.css';

interface IScenarioPersonalizationCommunicationProps {
  personalizedScenarioMsg: IPersonalizedMessage[];
  onScenarioMessageSave: (personalizationMsgData: IPersonalizationMsgCreate, personalizedMessageId: number | undefined, index: number, callback: (arg: boolean) => void) => void;
  addMoreScenarioMsgs: () => void;
  error: string;
  onCancel: (personalizationMessageType: PersonalizationMessageType) => void;
  onToggleChange: (mode: boolean, type: PersonalizationMessageType, index?: number) => void;
  onScenarioDelete: (msgId: number) => void;
  canEditMessage: boolean;
}

const ScenarioPersonalizationCommunication: FunctionComponent<IScenarioPersonalizationCommunicationProps> = (props) => {
  const intl = useIntl();
  const {
    personalizedScenarioMsg,
    onScenarioMessageSave,
    addMoreScenarioMsgs,
    error,
    onCancel,
    onToggleChange,
    onScenarioDelete,
    canEditMessage,
  } = props;
  
  const renderPersonalizedScenarioContainer = (scenarioMessage: IPersonalizedMessage, index: number) => {
    return (   
        <PersonalizationCommunication
          personalizedMessage={scenarioMessage}
          personalizationMessageType={PersonalizationMessageType.SCENARIO_MESSAGE}
          onSave={onScenarioMessageSave}
          maxLength={MAX_PERSONALIZED_DASHBOARD_SECTION_LENGTH}
          index={index}
          error={error}
          onCancel={onCancel}
          onDelete={onScenarioDelete}
          onToggleChange={onToggleChange}
          canEditMessage={canEditMessage}
        />
    );
  };

  return (
    <>
      <div className={styles.ScenarioPersonalizationTitle}>
        {intl.formatMessage({ id: 'MursionPortal.Personalized.ScenarioPage.Section' })}
          <div className={styles.PersonalizationIcon}>
            <IconButton
              iconName={'pencil-alt'}
              title={intl.formatMessage({ id: 'MursionPortal.Contract.Edit' })}
              className={styles.editButton}
              onClick={addMoreScenarioMsgs}
              label={intl.formatMessage({ id: 'MursionPortal.Personalized.CreateNewScenario.Button.Label' })}
            />
        </div>
      </div>
      <div className={styles.ScenarioPersonalizationItemBody}>
        {personalizedScenarioMsg.map((scenarioMessage: IPersonalizedMessage, index: number) => {
          return (
            <div key={`${scenarioMessage.messageType}_${scenarioMessage?.id}`}>
              {renderPersonalizedScenarioContainer(scenarioMessage, index)}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ScenarioPersonalizationCommunication;
