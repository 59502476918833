import IProject, { ScenarioVersion, SoftwareType, TCreateScenarioProjectPart } from 'src/app/data/projects/interfaces/IProject';

export type ProjectUnion = IProject | TCreateScenarioProjectPart | null | undefined;

export function toggleAutoSimCheckboxVisibility(project: ProjectUnion) {
  return project?.scenarioVersion === ScenarioVersion.V3 && project?.softwareType === SoftwareType.WEB;
}

export function renderAutoSimCheckbox(project: ProjectUnion, autoSimContainer: JSX.Element) {
  return toggleAutoSimCheckboxVisibility(project) ? autoSimContainer : null;
}
