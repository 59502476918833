import React , { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IFilterItem, IItemBankList, IResourceList } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';
import { actions } from 'src/app/redux';
import AssignItem from 'src/components/AssignItem';
import LoadingOverlay from 'src/components/LoadingOverlay';
import cn from 'classnames';
import styles from 'src/layouts/common/ClientUsers/components/NewClientUsers/components/AssignTeamsContainer/AssignTeamsContainer.css';
import { ITEM_BANK_PAGE } from 'src/layouts/mursion/ScenarioBankContainer/scenarioBankPageId';
import ResourcesListControl from 'src/layouts/mursion/ScenarioBankContainer/components/common/ResourcesListControl/ResourcesListControl';
import BuildingBlockCreator from 'src/layouts/mursion/ScenarioBankContainer/components/common/BuildingBlockCreator/BuildingBlockCreator';
import { ITEM_BANK_CONTROLS } from 'src/layouts/mursion/ScenarioBankContainer/IScenarioBank';
import ErrorMessage from 'src/components/ErrorMessage';
import { validateSkills } from 'src/layouts/mursion/ScenarioBankContainer/utils/nextGenScenarioBankUtils';
import { useIntl } from 'react-intl';
import { MAX_SKILLS, MIN_SKILLS } from 'src/app/data/common/constants';

export enum ITEM_BANK_TYPE {
  RESOURCE = 'resource',
}

export enum ITEM_BANK_API_END_POINT {
  RESEARCH_SUMMARY = 'researchSummary',
}

export interface IAssignResourcesControlProps {
  itemBankType: ITEM_BANK_TYPE | ITEM_BANK_PAGE;
  searchTitle: string;
  title: string;
  toggleEditingAllow: boolean;
  buttonTitle: string;
  noItemAdded: string;
  viewMode: boolean;
  onChange: (resources: IItemBankList[]) => void;
  initialSelectedItemsInfo?: IItemBankList[];
  resourcesList?: IResourceList[] |  IFilterItem[] | null;
  label?: string;
  createNewButtonText?: string;
  itemBankControls?: ITEM_BANK_CONTROLS[];
  fetchAction?: Promise<IItemBankList[]>;
  showErrorMsg?: boolean;
}

const AssignResourcesControl: FunctionComponent<IAssignResourcesControlProps> = (props) => {
    const {
      itemBankType,
      searchTitle,
      title,
      toggleEditingAllow,
      buttonTitle,
      noItemAdded,
      onChange,
      initialSelectedItemsInfo,
      viewMode,
      resourcesList,
      label,
      createNewButtonText,
      itemBankControls,
      showErrorMsg = false,
      fetchAction = actions.nextGenScenario.getAllActiveItemBankListByType(itemBankType)
    } = props;     
      
      const dispatch = useDispatch();
      const intl = useIntl();
      const [resourcesInfo, setResourcesInfo] = useState<IItemBankList[]>([]);
      const [selectedResourcesInfo, setSelectedResourcesInfo] = useState<IItemBankList[]>([]);
      const [searchText, setSearchText] = useState<string>('');
      const [loading, setLoading] = useState(false);
      const [showModalDialog, setshowModalDialog] = useState(false);
      
      useEffect(() => {
        if(initialSelectedItemsInfo){
          setSelectedResourcesInfo(initialSelectedItemsInfo);
        }
      }, [initialSelectedItemsInfo,viewMode]);

      useEffect(() => {
        if(!viewMode){
          getAllResourcesData();
        }
      }, [viewMode]);

      const getAllResourcesData = () => {
        setLoading(true);
        dispatch(fetchAction).then(response => {
          if(response){
            setResourcesInfo(response);
          }
        }).finally(() =>  {
          setLoading(false); 
        });
      };

      const filteredAvailableResources = resourcesInfo.filter(
        (resourceInfo) =>
          !selectedResourcesInfo.some((resources) => resources.id === resourceInfo.id) &&
          (resourceInfo.name || '').toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );

      const onSearch = (text: string) => {    
        setSearchText(text);
      };

      const removeResources = (resourceId: string) => () => {
          const updatedResources = selectedResourcesInfo.filter((resource) => resource.id !== resourceId);
          setSelectedResourcesInfo(updatedResources);
          onChange(updatedResources);
      };
    
      const assignResources = (resourceId: string, teamName: string) => () => {
        const newResource = { id: resourceId, name: teamName };
        const updateResources = selectedResourcesInfo.concat(newResource);
        setSelectedResourcesInfo(updateResources);
        onChange(updateResources);
      };

      const renderSelectedItemsList = () => {
        return (
          <div className={styles.assignResourcesPreview}>
          <div className={cn(styles.title, 'bankTitle')}>{title}</div>
          <ResourcesListControl resourcesList={resourcesList ?? []} isResourceData={itemBankType === ITEM_BANK_TYPE.RESOURCE} />
        </div>
        );
      };      

      const onClickCreateNewBtn = () => {
        setshowModalDialog(true);  
      };

      const oncancel = () => {
         setshowModalDialog(false);         
      };

      const renderModal = () => {
        if(!showModalDialog){
          return null;
        }

        return (
           <BuildingBlockCreator
            show={showModalDialog}
            buildingBlockType={itemBankType as ITEM_BANK_PAGE}
            itemBankControls={itemBankControls}
            onCreateNewData={getAllResourcesData}
            oncancel={oncancel}
           />
        );
      };

      const renderErrorMsgContainer = () => {
        if (!showErrorMsg) {
          return null;
        } 

        let errorMsg = '';

        if (itemBankType === ITEM_BANK_PAGE.AVATAR && !selectedResourcesInfo.length) {
          errorMsg = intl.formatMessage({ id: 'MursionPortal.Scenario.Error.Msg' });
        }

        if (itemBankType === ITEM_BANK_PAGE.SKILL && !validateSkills(selectedResourcesInfo)) {
          errorMsg = intl.formatMessage(
            { id: 'MursionPortal.Scenario.Skills.Error.Msg' },
            {
              minSkills: intl.formatNumber(MIN_SKILLS),
              maxSkills: intl.formatNumber(MAX_SKILLS),
            }
          );
        }

        return <ErrorMessage message={errorMsg} />;
      };

    return (
    <LoadingOverlay active={loading} spinner={true}>
      { viewMode ?  renderSelectedItemsList() :
      <>
        <AssignItem
            title={title}
            buttonTitle={buttonTitle}
            searchTitle={searchTitle}
            searchText={searchText}
            onSearch={onSearch}
            availableItems={resourcesInfo}
            restAvailableItems={filteredAvailableResources || []}
            selectedItems={selectedResourcesInfo}
            add={assignResources}
            remove={removeResources}
            noItemAdded={noItemAdded}
            toggleEditingAllow={toggleEditingAllow}
            className={cn(styles.assignTeamUserWrap, styles.assignResourcesWrap)}
            label={label}
            createNewButtonText={createNewButtonText}
            onClickCreateNewBtn={onClickCreateNewBtn}
         />
         {renderErrorMsgContainer()}
         {renderModal()}
         </>
      }
      </LoadingOverlay>
    );
};

export default AssignResourcesControl;
