import React, { FunctionComponent } from 'react';
import stripHtml from 'src/app/data/common/utils/stripHtml';
import styles from 'src/layouts/common/ClientUsers/components/NewClientUsers/components/AssignTeamsContainer/AssignTeamsContainer.css';
import { IFilterItem, IResourceList } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';
import cn from 'classnames';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';

export interface IResourcesListControl {
    isResourceData?: boolean;
    resourcesList: IResourceList[] | IFilterItem[];
    className?: string;
}

export function formatPublishDate(publishDate: string) {
    let month;
    if (publishDate.length > 4) {
      month = publishDate.slice(4, 6);
    }
    return month ? `${month}/${publishDate.slice(0, 4)}` : publishDate.slice(0, 4);
  }

const ResourcesListControl: FunctionComponent<IResourcesListControl> = (props) => {
    const { resourcesList, className, isResourceData = false } = props;
    function renderItemBankData() {
        if (isResourceData) {
            return (
                resourcesList?.map((resourceList: IResourceList) => (
                    <li key={resourceList.id}>
                        <a href={resourceList.link} target='_blank' >{stripHtml(resourceList.title)}</a> | {resourceList.publisher} | {formatPublishDate(resourceList.publishDate)}
                    </li>
                ))
            );
        } else {
            return (
                resourcesList?.map((resourceList: IFilterItem) => (
                    <li key={resourceList.id}>
                        {getPlainText(resourceList.title)}
                    </li>
                ))
            );
        }
    }

    return (
        <div className={cn(styles.assignResourcesPreview, className)}>
            <ul>{renderItemBankData()}</ul>
        </div>
    );
};

export default ResourcesListControl;