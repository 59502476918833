import React, { FunctionComponent, useEffect, useState } from 'react';
import actions from 'src/app/redux/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { ILearnerPersonalizationMessage, PersonalizationMessageType } from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';
import styles from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/LearnerPersonalizationMessage/LearnerPersonalizationMessage.css';
import { Alert, Container } from 'react-bootstrap';
import scrapIcon from 'src/images/scrap.svg';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import stripHtml from 'src/app/data/common/utils/stripHtml';
import { SelectorTheme } from 'src/components/Selector/themes';

interface ILearnerPersonalizationMessageProps {
  personalizationMessageType: PersonalizationMessageType;
  scenarioId?: string;
  cardVariant?: string;
  className?: string;
  themeType?: SelectorTheme;
}

const LearnerPersonalizationMessage: FunctionComponent<ILearnerPersonalizationMessageProps> = (props) => {
  const dispatch = useDispatch();
  const clientId = useSelector(selectors.profile.getClientId);
  const intl = useIntl();

  const [personalizationMessage, setPersonalizationMessage] = useState<{title: string | undefined, message: string | undefined}>({
    title: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const { personalizationMessageType, scenarioId, cardVariant, className, themeType } = props;

  useEffect(() => {
    setLoading(true);
    dispatch(actions.scenarios.learners.fetchPersonalizedMessage(clientId, personalizationMessageType, scenarioId)).then(message => {
      if(message){
        setPersonalizedMessageByType(personalizationMessageType, message);
      }
    }).finally(() =>  {
      setLoading(false); 
    });
  }, [clientId, personalizationMessageType, scenarioId]);

  const setPersonalizedMessageByType = (messageType: PersonalizationMessageType, message: ILearnerPersonalizationMessage) => {
    switch (true) {
      case messageType === PersonalizationMessageType.SCENARIO_MESSAGE:
        setPersonalizationMessage({ title: message.titleMessage?.message, message: message.scenarioMessages?.[0].message });
        break;
      case messageType === PersonalizationMessageType.LEARNER_DASHBOARD_MESSAGE:
        setPersonalizationMessage({ title: message.titleMessage?.message, message: message.dashboardPageMessage?.message});
        break;
      case messageType === PersonalizationMessageType.SCHEDULING_PAGE_MESSAGE:
        setPersonalizationMessage({ title: message.titleMessage?.message, message: message.schedulingPageMessage?.message});
        break;
      default:
        setPersonalizationMessage({ title: '', message: ''});
    }
  };

  const hasPersonalizationContent = stripHtml(personalizationMessage?.message ?? '').trim().length;
  return (
    <>
      <LoadingOverlay active={loading} spinner={true}>
        {
          !!hasPersonalizationContent &&
          <Container className={cn(styles.personalizationMessageWrap, className, themeType === SelectorTheme.Cobalt && styles.cobaltPersonalizationMessageWrap)}>
            <div className={cn(styles.personalizationMessageTitle, cardVariant === 'info' && styles.personalizationMessageLabel)}> {personalizationMessage?.title && getPlainText(personalizationMessage.title)}</div>
            <Alert variant={cardVariant} className={styles.personalizationMessageAlert}>
              <img src={scrapIcon} alt={intl.formatMessage({ id: 'MursionPortal.Setting.LearnerCommunicationPersonalization'})} />
              {getPlainText(personalizationMessage.message!)}
            </Alert>
          </Container>
        }

      </LoadingOverlay>
    </>
  );
};
export default LearnerPersonalizationMessage;