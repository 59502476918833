import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { MAX_PERSONALIZED_DASHBOARD_SECTION_LENGTH } from 'src/app/data/common/constants';
import {
  IPersonalizationMsgCreate,
  PersonalizationMessageType,
} from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer';
import PersonalizationCommunication, {
  IPersonalizedMessage,
} from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/PersonalizationCommunication';
import IconButton from 'src/components/IconButton/IconButton';
import styles from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/ScenarioPersonalizationCommunication/ScenarioPersonalizationCommunication.css';
import PathwayStyles from 'src/layouts/common/ClientUsers/components/NewClientUsers/components/AssignTeamsContainer/AssignTeamsContainer.css';

interface IScenarioPersonalizationCommunicationProps {
  personalizedPathwayMsg: IPersonalizedMessage[];
  onPathwayMessageSave: (personalizationMsgData: IPersonalizationMsgCreate, personalizedMessageId: number | undefined, index: number, callback: (arg: boolean) => void) => void;
  addMorePathwayMsgs: () => void;
  error: string;
  onCancel: (personalizationMessageType: PersonalizationMessageType) => void;
  onToggleChange: (mode: boolean, type: PersonalizationMessageType, index?: number) => void;
  onScenarioDelete: (msgId: number) => void;
  canEditMessage: boolean;
}

const PathwayPersonalizationCommunication: FunctionComponent<IScenarioPersonalizationCommunicationProps> = (props) => {
  const intl = useIntl();
  const {
    personalizedPathwayMsg,
    onPathwayMessageSave,
    addMorePathwayMsgs,
    error,
    onCancel,
    onToggleChange,
    onScenarioDelete,
    canEditMessage,
  } = props;
  
  const renderPersonalizedScenarioContainer = (pathwayMessage: IPersonalizedMessage, index: number) => {
    return (   
        <PersonalizationCommunication
          personalizedMessage={pathwayMessage}
          personalizationMessageType={PersonalizationMessageType.PATHWAY_MESSAGE}
          onSave={onPathwayMessageSave}
          maxLength={MAX_PERSONALIZED_DASHBOARD_SECTION_LENGTH}
          index={index}
          error={error}
          canEditMessage={canEditMessage}
          onCancel={onCancel}
          onDelete={onScenarioDelete}
          onToggleChange={onToggleChange}
          containerClass={PathwayStyles.projectChips}
        />
    );
  };

  return (
    <>
      <div className={styles.ScenarioPersonalizationTitle}>
        {intl.formatMessage({ id: 'MursionPortal.Personalized.PathwayPage.Section' })}
          <div className={styles.PersonalizationIcon}>
            <IconButton
              title={intl.formatMessage({ id: 'MursionPortal.Contract.Edit' })}
              iconName={'pencil-alt'}
              label={intl.formatMessage({ id: 'MursionPortal.Personalized.CreateNewPathway.Button.Label' })}
              className={styles.editButton}
              onClick={addMorePathwayMsgs}
            />
        </div>
      </div>
      <div className={styles.ScenarioPersonalizationItemBody}>
        {personalizedPathwayMsg.map((pathwayMessage: IPersonalizedMessage, index: number) => {
          return (
            <div key={`${pathwayMessage.messageType}_${pathwayMessage?.id}`}>
              {renderPersonalizedScenarioContainer(pathwayMessage, index)}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PathwayPersonalizationCommunication;
