import LoadChunk from 'src/app/services/LoadChunk/LoadChunk';

const CompanyCalendar = LoadChunk(() => import('src/layouts/mursion/CompanyCalendar/CompanyCalendar'));
const Clients = LoadChunk(() => import('src/layouts/mursion/Clients'));
const ProviderSettings = LoadChunk(() => import('src/layouts/mursion/ProviderSettings'));

const Trainings = LoadChunk(() => import('src/layouts/mursion/Trainings'));
const Requests = LoadChunk(() => import('src/layouts/mursion/Requests'));
const CompanyUsers = LoadChunk(() => import('src/layouts/mursion/CompanyUsers'));
const ScenarioBank = LoadChunk(() => import('src/layouts/mursion/ScenarioBankContainer/ScenarioBankContainer'));

export default {
  CompanyCalendar,
  Clients,
  ProviderSettings,
  Trainings,
  Requests,
  CompanyUsers,
  ScenarioBank
};
