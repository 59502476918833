import { noop } from 'lodash';
import React from 'react';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import { IUserRoleExtended } from 'src/app/data/profile/interfaces';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import { IPublishReady } from 'src/layouts/mursion/ScenarioBankContainer/components/common/PublishScenarioContainer/PublishScenarioContainer';
import IScenario, { ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import { SCENARIO_MENUS_ITEM } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/NextGenScenariosSideMenu/nextGenSideMenuConfig';

interface IGuide {
    avatarPerspectives: boolean;
    prepGuide: boolean;
    reflectionGuide: boolean;
    allFilled?: boolean;
}

interface IEvents {
    eventDetails: boolean;
    allFilled?: boolean;
}

interface IScenarioDetails {
    content: boolean;
    deliveryDetails: boolean;
    libraryInfo: boolean;
    scenarioDocuments: boolean;
    allFilled?: boolean;
}

interface IScoring {
    assessment: boolean;
    synopsis: boolean;
    allFilled?: boolean;
}

interface IPlanning {
    timelineAndScheduling: boolean;
    assignedTeams: boolean;
    certifiedSS: boolean;
    inactiveSS: boolean;
    practiceSS: boolean;
    allFilled: boolean;
}

export interface ISideMenuItemsState {
    avatarGuide: IGuide;
    events: IEvents;
    scenarioDetails: IScenarioDetails;
    scoring: IScoring;
    planning: IPlanning;
}

export const initialSideMenuItemsState = {
    avatarGuide: {
        avatarPerspectives: false,
        prepGuide: false,
        reflectionGuide: false,
        allFilled: false,
    },
    events: {
        eventDetails: false,
        allFilled: false,
    },
    scenarioDetails: {
        content: false,
        deliveryDetails: false,
        libraryInfo: false,
        scenarioDocuments: false,
        allFilled: false,
    },
    scoring: {
        assessment: false,
        synopsis: false,
        allFilled: false,
    },
    planning: {
        timelineAndScheduling: false,
        assignedTeams: false,
        certifiedSS: false,
        inactiveSS: false,
        practiceSS: false,
        allFilled: false,
    },
};

export interface IScenarioContextData extends IPublishReady {
    archived: boolean;
}

export const initialScenarioContextDataState = {
    archived: false,
    publishReady: false,
    status: ScenarioStatus.DRAFT,
    publishDate: null,
    publishedBy: null,
    originId: null,
    orphan: false,
};

const NextGenScenarioContext = React.createContext<{
    companyConfig: ICompanyConfig | null,
    userRole: IUserRoleExtended | null,
    projectId: string | null,
    scenarioId: string | null,
    isNextGenScenarioEnabled: boolean | undefined,
    canEditScenario: boolean,
    scenarioVersion: ScenarioVersion | undefined,
    certificateId: string | null,
    isPracticeSessionEnabled: boolean | undefined;
    setCertificateId: (certificateId: string) => void,
    sideMenuItems: {
        /** indicates whether side menu items are filled or empty */
        sideMenuItemsState: ISideMenuItemsState,
        refreshSideMenuItemsState: () => void,
    },
    scenarioContextDataState: {
        scenarioContextData: IScenarioContextData,
        refreshScenarioContextData: () => void,
    },
    scenarioInfo: {
        scenarioInfoState: Partial<IScenario> | null,
        setScenarioInfoState: (data: Partial<IScenario>) => void,
    },
    canViewTeam: boolean;
    projectTimeZoneId: string | null | undefined;
    demandBasedScheduling: boolean | undefined;
    isAddAndEditEvent: boolean;
    setIsAddAndEditEventHandler: (flag: boolean) => void;
    fetchCurrentTabName: (name: SCENARIO_MENUS_ITEM) => void;
}>({
    companyConfig: null,
    userRole: null,
    projectId: null,
    scenarioId: null,
    isNextGenScenarioEnabled: undefined,
    canEditScenario: false,
    scenarioVersion: undefined,
    certificateId: null,
    isPracticeSessionEnabled: undefined,
    setCertificateId: (_: string) => {
        // No implementation needed
    },
    sideMenuItems: {
        sideMenuItemsState: initialSideMenuItemsState,
        refreshSideMenuItemsState: noop,
    },
    scenarioContextDataState: {
        scenarioContextData: initialScenarioContextDataState,
        refreshScenarioContextData: noop,
    },
    scenarioInfo: {
        scenarioInfoState: null,
        setScenarioInfoState: noop
    },
    canViewTeam: false,
    projectTimeZoneId: null,
    demandBasedScheduling: undefined,
    isAddAndEditEvent: false,
    setIsAddAndEditEventHandler: (_: boolean) => {
        // No implementation needed
    },
    fetchCurrentTabName: (_: SCENARIO_MENUS_ITEM) => {
        // No implementation needed
    },
});

export default NextGenScenarioContext;