import { MAX_SKILLS, MIN_SKILLS } from 'src/app/data/common/constants';
import { isCurrentUserClientUser, isCurrentUserPSorOps, isCurrentUserSimDesigner } from 'src/app/data/common/utils/userRoleUtils';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { IResourcesItems, ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import { CreateSessionType } from 'src/app/data/session/interfaces/ISession';
import { ButtonType } from 'src/components/Button';
import styles from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioDetails/ScenarioContentContainer/ScenarioContentContainer.css';

export function setTextControlInputData(text: string | undefined | null = '') {
  return { text: text || '', limitExceeded: false, valid: true };
}

export function getButtonTheme(
  disableScheduleBtnForLearner: boolean,
  blueButton: ButtonType,
  bluOutlineButton: ButtonType
) {
  return disableScheduleBtnForLearner ? blueButton : bluOutlineButton;
}

export function isScenarioStartedOrUpcoming(scenarioStatus: ScenarioStatus | undefined) {
  return scenarioStatus === ScenarioStatus.STARTED || scenarioStatus === ScenarioStatus.UPCOMING;
}

function getCreateSessionTypeForProviderUsers(userRole: IUserRoleExtended | null) {
  return isCurrentUserPSorOps(userRole) ? CreateSessionType.EmergencySession : CreateSessionType.Training;
}

export function getDefaultCreateSessionType(userRole: IUserRoleExtended | null) {
  return isCurrentUserClientUser(userRole) ? CreateSessionType.Session : getCreateSessionTypeForProviderUsers(userRole);
}

export function getInviteToScheduleButtonVisibility(
  isProjectScenario: boolean,
  isInviteToScheduleButtonVisible: boolean
) {
  return isProjectScenario && isInviteToScheduleButtonVisible;
}

export function getBackButtonLabel(isProjectScenario: boolean) {
  return isProjectScenario
    ? 'MursionPortal.ScenarioCard.BackToProject'
    : 'MursionPortal.ScenarioBank.ItemBank.BackButton.Label';
}

export function getInviteToScheduleButtonColumnWidth(showInviteToScheduleButton: boolean, md1: number, md2: number) {
  return showInviteToScheduleButton ? md1 : md2;
}

export enum NextGenScenarioSelectorType {
  AVATAR = 'AVATAR',
  DOMAIN = 'DOMAIN',
  EVENT = 'EVENT',
  LEARNER_INSTRUCTION = 'LEARNER_INSTRUCTION',
  MINDSET = 'MINDSET',
  OUTCOME = 'OUTCOME',
  RESEARCH_SUMMARY = 'RESEARCH_SUMMARY',
  SKILL = 'SKILL',
}

export const getScenarioBankAndSDRole = (userRole: IUserRoleExtended | null, projectId: string) => {
  return isCurrentUserSimDesigner(userRole) && !projectId;
};

export const getPreviewMode = (libraryInfoViewMode: boolean) => {
  return libraryInfoViewMode && styles.previewMode;
};

export const getColumnSize = (libraryInfoViewMode: boolean, viewModeSize: number, editModeSize: number) => {
  return libraryInfoViewMode ? viewModeSize : editModeSize;
};

export const validateSkills = (skill: IResourcesItems[]) => {
  return skill.length >= MIN_SKILLS && skill.length <= MAX_SKILLS;
};