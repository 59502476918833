import { IItemBankControls } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankCreate/ItemBankCreate';
import { ITEM_BANK_PAGE } from 'src/layouts/mursion/ScenarioBankContainer/scenarioBankPageId';

export default function checkIfItemBankDataInValid(
  buildingBlockData: IItemBankControls,
  isTitleLimitExceeded: boolean,
  isDescriptionLimitExceeded: boolean,
  isTitleValid: boolean,
  isLinkValid: boolean,
  isPublisherLimitExceed: boolean,
): boolean {
  const { title, buildingBlockType, level, domain, mindsetType, outcomeLevelType, description, link, publisher, selectedYear, selectedResourcesInfo } = buildingBlockData;
  switch (true) {
    case buildingBlockType === ITEM_BANK_PAGE.DOMAIN:
    case buildingBlockType === ITEM_BANK_PAGE.EVENT:
    case buildingBlockType === ITEM_BANK_PAGE.PATHWAY:
    case buildingBlockType === ITEM_BANK_PAGE.LEARNER_INSTRUCTION:
    case buildingBlockType === ITEM_BANK_PAGE.AVATAR:
      return !title || isTitleLimitExceeded || isDescriptionLimitExceeded || !buildingBlockType || !isTitleValid;
    case buildingBlockType === ITEM_BANK_PAGE.SKILL:
      return !title || isTitleLimitExceeded || isDescriptionLimitExceeded || !buildingBlockType || !isTitleValid || !level || !domain;
    case buildingBlockType === ITEM_BANK_PAGE.MINDSET:
      return !title || isTitleLimitExceeded || isDescriptionLimitExceeded || !buildingBlockType || !isTitleValid || !mindsetType;
    case buildingBlockType === ITEM_BANK_PAGE.RESEARCH_SUMMARY:
      return !title || isTitleLimitExceeded || isDescriptionLimitExceeded || !buildingBlockType || !isTitleValid || !description || !selectedResourcesInfo.length;
    case buildingBlockType === ITEM_BANK_PAGE.RESOURCE:
      return !title || isTitleLimitExceeded || isDescriptionLimitExceeded || !isTitleValid || !isLinkValid || isPublisherLimitExceed || !link || !publisher || !selectedYear;  
    case buildingBlockType === ITEM_BANK_PAGE.OUTCOME:
        return !title || isTitleLimitExceeded || isDescriptionLimitExceeded || !buildingBlockType || !isTitleValid || !outcomeLevelType;       
    default:
      return !title || isTitleLimitExceeded || isDescriptionLimitExceeded || !buildingBlockType || !isTitleValid;
  }
}