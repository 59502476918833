import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styles from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer.css';
import PersonalizationCommunication, {
  IPersonalizedMessage,
} from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/PersonalizationCommunication';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import LoadingOverlay from 'src/components/LoadingOverlay';
import actions from 'src/app/redux/store/actions';
import { Container, Row , Col} from 'react-bootstrap';
import LazyImage from 'src/components/LazyImage/LazyImage';
import { getS3BucketURL } from 'src/s3bucketurls/S3BucketUrlUtils';
import { MAX_PERSONALIZED_LEARNER_DASHBOARD_SECTION_LENGTH, MAX_PERSONALIZED_SCHEDULING_SECTION_LENGTH, MAX_PERSONALIZED_TITLE_LENGTH } from 'src/app/data/common/constants';
import ScenarioPersonalizationCommunication from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/ScenarioPersonalizationCommunication/ScenarioPersonalizationCommunication';
import { useParams } from 'react-router';
import PathwayPersonalizationCommunication from './components/PathwayPersonalizationCommunication/PathwayPersonalizationCommunication';

export enum PersonalizationMessageType {
  MESSAGE_TITLE = 'MESSAGE_TITLE',
  LEARNER_DASHBOARD_MESSAGE = 'LEARNER_DASHBOARD_MESSAGE',
  SCHEDULING_PAGE_MESSAGE = 'SCHEDULING_PAGE_MESSAGE',
  SCENARIO_MESSAGE = 'SCENARIO_MESSAGE',
  PATHWAY_MESSAGE = 'PROJECT_MESSAGE',
}

export interface ILearnerPersonalizationMessage {
  titleMessage: IPersonalizedMessage | null;
  schedulingPageMessage: IPersonalizedMessage | null;
  dashboardPageMessage: IPersonalizedMessage | null;
  scenarioMessages: IPersonalizedMessage[] | null;
  projectMessages: IPersonalizedMessage[] | null;
}

export interface IPersonalizationMsgCreate {
  clientId: string;
  message: string;
  messageType: PersonalizationMessageType;
  entityIds: string[] | null | undefined;
}

export interface IExtendedPersonalizationMsgUpdate extends IPersonalizationMsgCreate {
  messageId: number;
}

const DEFAULT_SCENARIO_DATA: IPersonalizedMessage = {
  message: '',
  messageType: PersonalizationMessageType.SCENARIO_MESSAGE,
  scenarios: [],
};

const DEFAULT_PATHWAY_DATA: IPersonalizedMessage = {
  message: '',
  messageType: PersonalizationMessageType.PATHWAY_MESSAGE,
  scenarios: [],
};

export interface IPersonalizationError {
  personalizedTitleSectionError: string;
  personalizedDashboardSectionError: string;
  personalizedSchedulingSectionError: string;
  personalizedScenarioSectionError: string;
  personalizedPathwaySectionError: string;
}

const PersonalizationCommunicationContainer: FunctionComponent = () => {
  const intl = useIntl();
  const { clientID } = useParams<{ clientID: string }>();
  const idForClientUsers = useSelector(selectors.profile.getClientId);
  const dispatch = useDispatch();
  const bottomRef = useRef<HTMLDivElement>(null);

  const clientId = idForClientUsers || clientID;

  const initialErrorMsg: IPersonalizationError = {
    personalizedTitleSectionError: '',
    personalizedDashboardSectionError: '',
    personalizedSchedulingSectionError: '',
    personalizedScenarioSectionError: '',
    personalizedPathwaySectionError: '',
  };

  const [personalizedMsgTitle, setPersonalizedMsgTitle] = useState<IPersonalizedMessage | null>(null);
  const [personalizedDashboardMsg, setPersonalizedDashboardMsg] = useState<IPersonalizedMessage | null>(null);
  const [personalizedSchedulingMsg, setPersonalizedSchedulingMsg] = useState<IPersonalizedMessage | null>(null);
  const [personalizedScenarioMsg, setPersonalizedScenarioMsg] = useState<IPersonalizedMessage[]>([
     DEFAULT_SCENARIO_DATA,
  ]);
  const [initialPersonalizedScenarioMsg, setInitialPersonalizedScenarioMsg] = useState<IPersonalizedMessage[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [scrollable, setScrollable] = useState<boolean>(false);
  const [error, setError] = useState<IPersonalizationError>(initialErrorMsg);  
  const [initialPersonalizedPathwayMsg, setInitialPersonalizedPathwayMsg] = useState<IPersonalizedMessage[] | null>(null);
  const [personalizedPathwayMsg, setPersonalizedPathwayMsg] = useState<IPersonalizedMessage[]>([
    DEFAULT_PATHWAY_DATA,
 ]);

  useEffect(() => {
    getAllMessages();
  }, []);

  useEffect(() => {
    if (scrollable) {
      scrollToBottom();
    }
  }, [personalizedScenarioMsg.length, personalizedPathwayMsg.length]);

  const ifAnyMessageOpenedForEditing = useMemo(
    () =>
      personalizedMsgTitle?.editMode ||
      personalizedDashboardMsg?.editMode ||
      personalizedSchedulingMsg?.editMode ||
      personalizedPathwayMsg.findIndex((state) => state?.editMode) > -1 ||
      personalizedScenarioMsg.findIndex((state) => state?.editMode) > -1,
    [personalizedMsgTitle, personalizedDashboardMsg, personalizedSchedulingMsg, personalizedScenarioMsg, personalizedPathwayMsg]
  );

  const getAllMessages = () => {
    setLoading(true);
    dispatch(actions.scenarios.learners.fetchPersonalizedMessage(clientId)).then(response => {
      if(response){
       setMessagesData(response);
      }
    }).finally(() =>  {
      setLoading(false); 
    });
  };

  const setMessagesData = (response: ILearnerPersonalizationMessage) => {
    setPersonalizedMsgTitle(response.titleMessage);
    setPersonalizedDashboardMsg(response.dashboardPageMessage);
    setPersonalizedSchedulingMsg(response.schedulingPageMessage);
    setPersonalizedScenarioMsg(response.scenarioMessages || [DEFAULT_SCENARIO_DATA]);
    setPersonalizedPathwayMsg(response.projectMessages || [DEFAULT_PATHWAY_DATA]);
  };

  const onSave = (personalizedMsgData: IPersonalizationMsgCreate, personalizedMessageId: number | undefined, index: number, callback: (arg: boolean) => void) => {
    setLoading(true);
    const method = personalizedMessageId ? 'PUT' : 'POST';
    const payload = personalizedMessageId ? { ...personalizedMsgData, id: personalizedMessageId } : personalizedMsgData;
    dispatch(actions.scenarios.learners.addPersonalizedMessage(payload, method))
      .then((message) => {
        callback(false);
        if (message) {
          setMessageByType(personalizedMsgData, message, index);
        }
      })
      .catch((e) => {
        callback(true);
        if(e.validations[0].message){
         setErrorMsgByType(personalizedMsgData.messageType,e.validations[0].message);
         }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setMessageByType = (personalizedMsgData: IPersonalizationMsgCreate, message: IPersonalizedMessage, index: number) => {
    switch (true) {
      case personalizedMsgData.messageType === PersonalizationMessageType.MESSAGE_TITLE:
        setPersonalizedMsgTitle(message);
        break;
      case personalizedMsgData.messageType === PersonalizationMessageType.LEARNER_DASHBOARD_MESSAGE:
        setPersonalizedDashboardMsg(message);
        break;
      case personalizedMsgData.messageType === PersonalizationMessageType.SCHEDULING_PAGE_MESSAGE:
        setPersonalizedSchedulingMsg(message);
        break;
      case personalizedMsgData.messageType === PersonalizationMessageType.SCENARIO_MESSAGE:
        setInitialPersonalizedScenarioMsg(null);
        setPersonalizedScenarioMsg((prevState) => {
          return prevState.map((prevStateValue, i) => {
            return i === index ? message : prevStateValue;
          });
        });
        break;
      case personalizedMsgData.messageType === PersonalizationMessageType.PATHWAY_MESSAGE:
        setInitialPersonalizedPathwayMsg(null);
        setPersonalizedPathwayMsg((prevState) => {
          return prevState.map((prevStateVal, indexValue) => {
            return indexValue === index ? message : prevStateVal;
          });
        });
        break;
    }
  };

  const setErrorMsgByType = (personalizedMsgType: PersonalizationMessageType, message: string = '') => {
    switch (true) {
      case personalizedMsgType === PersonalizationMessageType.MESSAGE_TITLE:
        setError({ ...error, personalizedTitleSectionError: message });
        break;
      case personalizedMsgType === PersonalizationMessageType.LEARNER_DASHBOARD_MESSAGE:
        setError({ ...error, personalizedDashboardSectionError: message });
        break;
      case personalizedMsgType === PersonalizationMessageType.SCHEDULING_PAGE_MESSAGE:
        setError({ ...error, personalizedSchedulingSectionError: message });
        break;
      case personalizedMsgType === PersonalizationMessageType.SCENARIO_MESSAGE:
        setError({ ...error, personalizedScenarioSectionError: message });
        if (initialPersonalizedScenarioMsg?.length) {
          setPersonalizedScenarioMsg(initialPersonalizedScenarioMsg);
        }
        break;
      case personalizedMsgType === PersonalizationMessageType.PATHWAY_MESSAGE:
        setError({ ...error, personalizedPathwaySectionError: message });
        if (initialPersonalizedPathwayMsg?.length) {
          setPersonalizedPathwayMsg(initialPersonalizedPathwayMsg);
        }
        break;
      default:
        setError({ ...error });
    }
  };

  const addMore = () => {
    setPersonalizedScenarioMsg((prevState) => {
      if (prevState.findIndex((state) => !state?.id) === -1) {
        setInitialPersonalizedScenarioMsg(prevState);
        return prevState.concat({...DEFAULT_SCENARIO_DATA, editMode: !ifAnyMessageOpenedForEditing});
      } else {
        scrollToBottom();
        return prevState.map((state) => {
          if (!state?.id) {
            return { ...state, editMode: !ifAnyMessageOpenedForEditing };
          }
          return state;
        });
      }
    });
    setScrollable(true);
  };

  const addMorePathway = () => {
    setPersonalizedPathwayMsg((prevState) => {
      if (prevState.findIndex((state) => !state?.id) === -1) {
        setInitialPersonalizedPathwayMsg(prevState);
        return prevState.concat({...DEFAULT_PATHWAY_DATA, editMode: !ifAnyMessageOpenedForEditing});
      } else {
        scrollToBottom();
        return prevState.map((stateValue) => {
          if (!stateValue?.id) {
            return { ...stateValue, editMode: !ifAnyMessageOpenedForEditing };
          }
          return stateValue;
        });
      }
    });
    setScrollable(true);
  };

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const changeEditModeValue = (prevState: IPersonalizedMessage | null, mode: boolean, type: PersonalizationMessageType) => {
    if (prevState) {
      return { ...prevState, editMode: mode };
    } else {
      return { message: '', messageType: type, editMode: mode };
    }
  };

  const onToggleMessage = (mode: boolean, type: PersonalizationMessageType, index: number) => {
    switch (true) {
      case type === PersonalizationMessageType.MESSAGE_TITLE:
        setPersonalizedMsgTitle((prevState) => {
          return changeEditModeValue(prevState, mode, type);
        });
        break;
      case type === PersonalizationMessageType.LEARNER_DASHBOARD_MESSAGE:
        setPersonalizedDashboardMsg((prevState) => {
          return changeEditModeValue(prevState, mode, type);
        });
        break;
      case type === PersonalizationMessageType.SCHEDULING_PAGE_MESSAGE:
        setPersonalizedSchedulingMsg((prevState) => {
          return changeEditModeValue(prevState, mode, type);
        });
        break;
      case type === PersonalizationMessageType.PATHWAY_MESSAGE:
        setPersonalizedPathwayMsg((prevState) => {
          return prevState.map((stateValue, i) => {
            return {
              ...stateValue,
              editMode: i === index ? mode : stateValue.editMode,
            };
          });
        });
        break;
      default:
        setPersonalizedScenarioMsg((prevState) => {
          return prevState.map((prevStateValue, i) => {
            return {
              ...prevStateValue,
              editMode: i === index ? mode : prevStateValue.editMode,
            };
          });
        });
    }
  };

  const onDeleteMessage = (messageId: number) => {
    setInitialPersonalizedScenarioMsg(null);
    dispatch(actions.scenarios.learners.deletePersonalizedMessage(messageId)).then(response => {
      if(response){
        getAllMessages();
      }
    });
  };

  return (
      <LoadingOverlay active={loading} spinner={true}>
      <Container className={styles.personalizationCommunicationContainer}>
        <Row className={styles.sectionHeader}>
          <Col md={9}>
            <div className={styles.title}>
              {intl.formatMessage({ id: 'MursionPortal.Portal.Messaging.Heading.Text' })}
            </div>
            <div className={styles.summary}>
              {intl.formatMessage({ id: 'MursionPortal.Portal.Messaging.SubHeading.Text' })}
            </div>
          </Col>
          <Col md={3}>
            <LazyImage
              src={getS3BucketURL('images', 'app/uniqueUrandVoice.png')}
              altText={intl.formatMessage({ id: 'MursionPortal.Portal.Messaging.Heading.Text' })}
              imageAutoWidth={true}
            />
          </Col>
        </Row>
        <div className={styles.bodyContent}>
          <PersonalizationCommunication
            headingText={intl.formatMessage({ id: 'MursionPortal.PersonalizedMessageTitle' })}
            personalizedMessage={personalizedMsgTitle}
            personalizationMessageType={PersonalizationMessageType.MESSAGE_TITLE}
            maxLength={MAX_PERSONALIZED_TITLE_LENGTH}
            onSave={onSave}
            error={error.personalizedTitleSectionError}
            onCancel={setErrorMsgByType}
            onToggleChange={onToggleMessage}
            canEditMessage={!ifAnyMessageOpenedForEditing}
          />
          <PersonalizationCommunication
            headingText={intl.formatMessage({ id: 'MursionPortal.Personalized.LearnerDashboard.Section' })}
            personalizedMessage={personalizedDashboardMsg}
            personalizationMessageType={PersonalizationMessageType.LEARNER_DASHBOARD_MESSAGE}
            onSave={onSave}
            maxLength={MAX_PERSONALIZED_LEARNER_DASHBOARD_SECTION_LENGTH}
            error={error.personalizedDashboardSectionError}
            onCancel={setErrorMsgByType}
            onToggleChange={onToggleMessage}
            canEditMessage={!ifAnyMessageOpenedForEditing}

          />
          <PersonalizationCommunication
            headingText={intl.formatMessage({ id: 'MursionPortal.Personalized.SchedulingPage.Section' })}
            personalizedMessage={personalizedSchedulingMsg}
            personalizationMessageType={PersonalizationMessageType.SCHEDULING_PAGE_MESSAGE}
            onSave={onSave}
            maxLength={MAX_PERSONALIZED_SCHEDULING_SECTION_LENGTH}
            error={error.personalizedSchedulingSectionError}
            onCancel={setErrorMsgByType}
            onToggleChange={onToggleMessage}
            canEditMessage={!ifAnyMessageOpenedForEditing}
          />
          <ScenarioPersonalizationCommunication
            personalizedScenarioMsg={personalizedScenarioMsg}
            onScenarioMessageSave={onSave}
            addMoreScenarioMsgs={addMore}
            error={error.personalizedScenarioSectionError}
            onCancel={setErrorMsgByType}
            onToggleChange={onToggleMessage}
            onScenarioDelete={onDeleteMessage}
            canEditMessage={!ifAnyMessageOpenedForEditing}
          />
          <PathwayPersonalizationCommunication
            personalizedPathwayMsg={personalizedPathwayMsg}
            onPathwayMessageSave={onSave}
            addMorePathwayMsgs={addMorePathway}
            error={error.personalizedPathwaySectionError}
            onCancel={setErrorMsgByType}
            onToggleChange={onToggleMessage}
            onScenarioDelete={onDeleteMessage}
            canEditMessage={!ifAnyMessageOpenedForEditing}
          />
          <div ref={bottomRef} />
        </div>
        </Container>
      </LoadingOverlay>
  );
};
export default PersonalizationCommunicationContainer;
