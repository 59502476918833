import React, { FunctionComponent} from 'react';
import { IntlShape } from 'react-intl';
import styles from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankCreate/ItemBankCreate.css';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';

export interface ICancelAndSaveButtonsProps {
  intl: IntlShape;
  onCancelClick: () => void;
  isSaveBtnDisabled: boolean;
  onSaveClick: () => void;
}

const CancelAndSaveButtons: FunctionComponent<ICancelAndSaveButtonsProps> = (props) => {
  const { intl, onCancelClick, isSaveBtnDisabled, onSaveClick } = props;

  const getSubmitButtonTheme = () => {
   return isSaveBtnDisabled ? ButtonType.LIGHT : ButtonType.BLUE;
  };

  return (
    <div className={styles.buttonOptions}>
      <Button
        btnFont={ButtonFont.BOLD}
        btnSize={ButtonSize.MEDIUM}
        title={intl.formatMessage({ id: 'Users.Button.Cancel' })}
        aria-label={intl.formatMessage({ id: 'Users.Button.Cancel' })}
        onClick={onCancelClick}
        className={styles.cancelBtn}
      >
        {intl.formatMessage({ id: 'Users.Button.Cancel' })}
      </Button>
      <Button
        btnType={getSubmitButtonTheme()}
        btnFont={ButtonFont.BOLD}
        btnSize={ButtonSize.MEDIUM}
        disabled={isSaveBtnDisabled}
        title={intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
        aria-label={intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
        onClick={onSaveClick}
        className={styles.saveBtn}
      >
        {intl.formatMessage({ id: 'MursionPortal.Button.Save' })}
      </Button>
    </div>
  );
};

export default CancelAndSaveButtons;
