import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import A11yModal from 'src/components/A11yModal';
import { ITEM_BANK_PAGE } from 'src/layouts/mursion/ScenarioBankContainer/scenarioBankPageId';
import CancelAndSaveButtons from 'src/layouts/mursion/ScenarioBankContainer/components/common/CancelAndSaveButtons/CancelAndSaveButtons';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import TextEditingControl from 'src/layouts/mursion/ScenarioBankContainer/components/common/TextEditingControl/TextEditingControl';
import { ITEM_BANK_MAX_DESCRIPTION_LENGTH, ITEM_BANK_MAX_TITLE_LENGTH } from 'src/app/data/common/constants';
import styles from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankCreate/ItemBankCreate.css';
import checkIfItemBankDataInValid from 'src/layouts/mursion/ScenarioBankContainer/utils/checkIfItemBankDataInValid';
import LevelSelector, { LevelType } from 'src/layouts/mursion/ScenarioBankContainer/components/common/Level/LevelSelector';
import { SelectorTheme } from 'src/components/Selector';
import DomainSelector, { SelectorType } from 'src/layouts/mursion/ScenarioBankContainer/components/common/Domain/DomainSelector';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { actions } from 'src/app/redux';
import { ITEM_BANK_CONTROLS } from 'src/layouts/mursion/ScenarioBankContainer/IScenarioBank';
import { IItemBankControls } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankCreate/ItemBankCreate';
import LoadingOverlay from 'src/components/LoadingOverlay';
import cn from 'classnames';
import { getS3BucketURL } from 'src/s3bucketurls/S3BucketUrlUtils';

interface IBuildingBlockCreator {
    buildingBlockType: ITEM_BANK_PAGE;
    show: boolean;
    itemBankControls?: ITEM_BANK_CONTROLS[];
    titleLength?: number;
    descriptionLength?: number;
    onCreateNewData: () => void;
    oncancel: () => void;
}

const BuildingBlockCreator: FunctionComponent<IBuildingBlockCreator> = (props: IBuildingBlockCreator) => {
    const { buildingBlockType, titleLength, descriptionLength, itemBankControls = [], onCreateNewData, oncancel } = props;

    const dispatch = useDispatch();
    const intl = useIntl();

    const [buildingBlockData, setBuildingBlockData] = useState<IItemBankControls>({
        archived: false,
        version: 0,
        buildingBlockType,
        title: '',
        description: '',
        selectedResourcesInfo: [],
        level: null,
        domain: null,
    });

    const [isTitleLimitExceeded, setTitleLimitExceedValue] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [isDescriptionLimitExceeded, setDescriptionLimitExceedValue] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [isTitleValid, setIsTitleValid] = useState<boolean>(true);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const onLevelChange = (value: LevelType) => {
        setBuildingBlockData({ ...buildingBlockData, level: value });
    };

    const onTitleChange = (title: string, limitExceed: boolean, isInputValid: boolean) => {
        setBuildingBlockData({ ...buildingBlockData, title });
        setTitleLimitExceedValue(limitExceed);
        setIsTitleValid(isInputValid);
    };

    const onDomainChange = (domainVal: INamedEntry) => {
        setBuildingBlockData({ ...buildingBlockData, domain: domainVal });
    };

    const onDescriptionChange = (description: string, descriptionLimitExceed: boolean) => {
        setBuildingBlockData({ ...buildingBlockData, description });
        setDescriptionLimitExceedValue(descriptionLimitExceed);
    };

    function renderModalBodyContent() {
        return (
            <>
                <Row>
                    <Col>
                        {
                            <div className={styles.editTitle}>
                                <TextEditingControl
                                    label={'Users.TableModal.Title'}
                                    maxLength={titleLength ?? ITEM_BANK_MAX_TITLE_LENGTH}
                                    viewMode={false}
                                    onValueChange={onTitleChange}
                                    editorHeight={140}
                                    required={true}
                                />
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            <div className={styles.editDescription}>
                                <TextEditingControl
                                    label={'MursionPortal.Description'}
                                    maxLength={descriptionLength ?? ITEM_BANK_MAX_DESCRIPTION_LENGTH}
                                    viewMode={false}
                                    onValueChange={onDescriptionChange}
                                    editorHeight={220}
                                />
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    {itemBankControls.includes(ITEM_BANK_CONTROLS.LEVEL) && (
                        <Col lg={6} className={styles.buildingBlockCol}>
                            <LevelSelector
                                className={styles.levelSelector}
                                value={buildingBlockData.level}
                                viewMode={false}
                                onChange={onLevelChange}
                                themeType={SelectorTheme.Blue}
                                isMulti={false}
                                isSearchable={false}
                                placeholder={intl.formatMessage({ id: 'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder' })}
                                label={intl.formatMessage({ id: 'MursionPortal.ScenarioBank.ItemBank.Column.Level' })}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    {itemBankControls.includes(ITEM_BANK_CONTROLS.DOMAIN) && (
                        <Col lg={12} className={styles.buildingBlockCol}>
                            <DomainSelector
                                value={buildingBlockData.domain}
                                viewMode={false}
                                onChange={onDomainChange}
                                themeType={SelectorTheme.Blue}
                                isMulti={false}
                                placeholder={intl.formatMessage({ id: 'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder' })}
                                label={intl.formatMessage({ id: 'MursionPortal.ScenarioBank.ItemBank.Column.Domain' })}
                                type={SelectorType.SELECT}
                            />
                        </Col>
                    )}
                </Row>
            </>
        );
    }

    function onCancelBtnClick() {
        setShow(false);
        oncancel();
    }

    const onSaveBtnClick = async () => {
        const { archived, version, title, description, level, domain } = buildingBlockData;
        const data = {
            archived,
            version,
            buildingBlockType: buildingBlockData.buildingBlockType,
            title,
            description,
        };
        const requestPayload = { ...data, level: level ?? LevelType.L1, domainIds: domain ? [domain.id] : [] };
        setLoading(true);
        dispatch(actions.scenarios.saveItemBankData(requestPayload))
            .then(() => {
                onCreateNewData();
                setShow(false);
                oncancel();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const checkIfDataIsInValid = () => {
        return checkIfItemBankDataInValid(
            buildingBlockData,
            isTitleLimitExceeded,
            isDescriptionLimitExceeded,
            isTitleValid,
            true,
            false,
        );
    };

    const cancelAndSaveButton = useMemo(() => {
        return (
            <CancelAndSaveButtons
                intl={intl}
                onCancelClick={onCancelBtnClick}
                isSaveBtnDisabled={checkIfDataIsInValid()}
                onSaveClick={onSaveBtnClick}
            />
        );
    }, [buildingBlockData, isTitleValid, isDescriptionLimitExceeded, isTitleLimitExceeded]);

    function onHide() {
        setShow(false);
        oncancel();
    }

    return (
        <LoadingOverlay active={loading} spinner={true}>
        <A11yModal show={show} onHide={onHide} size={'lg'} className={styles.createNewSkillModal} centerModal={true}>
            <Modal.Header closeButton={false} className={styles.modalHeader}>
                <Modal.Title className={styles.modalTitle}>
                    {intl.formatMessage({ id: 'MursionPortal.NextGen.CreateNewSkill.Header.Text' })}
                </Modal.Title>
                <button className={styles.modalCloseBtn} 
                    onClick={onCancelBtnClick}
                    aria-label={intl.formatMessage({ id: 'MursionPortal.NextGen.CreateNewSkill.Header.Text' })}
                >
                    <img src={getS3BucketURL('images','app/closeModalx.svg')} alt={intl.formatMessage({ id: 'MursionPortal.NextGen.CreateNewSkill.Header.Text' })}/>
                </button>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
                {
                    renderModalBodyContent()
                }
            </Modal.Body>
            <Modal.Footer
                className={cn(styles.modalFooter, 'justify-content-center')}
            >
                {cancelAndSaveButton}
            </Modal.Footer>
        </A11yModal>
        </LoadingOverlay>
    );
};

export default BuildingBlockCreator;
