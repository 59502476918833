import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useIntl } from 'react-intl';
import { DEFAULT_USER_PROFILE_UPLOAD_ICON_PATH } from 'src/app/data/client/data';
import IUser from 'src/app/data/common/interfaces/IUser';
import getUserName from 'src/app/data/common/utils/getUserName';
import LazyImage from 'src/components/LazyImage/LazyImage';
import ListItem from 'src/components/ListView/components/ListItem';
import { DEFAULT_SIZE_PER_PAGE_LIST } from 'src/components/Pagination/Pagination';
import actions from 'src/app/redux/store/actions';
import styles from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/PersonalizationMessagesMetadata/PersonalizationMessagesMetadata.css';
import { useDispatch } from 'react-redux';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { Card } from 'react-bootstrap';
import closeIcon from 'src/images/closeIcon.svg';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';

interface IPersonalizationMessagesMetadataProps {
  timezone: string;
  onClose: () => void;
  id: number;
}

export interface IPersonalizationMessagesMetadata {
  operatedOn: number;
  operatedBy: IUser;
}

const PersonalizationMessagesMetadata: FunctionComponent<IPersonalizationMessagesMetadataProps> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { timezone, onClose, id } = props;
  const [personalizationMetadata, setPersonalizationMetadata] = useState<IPersonalizationMessagesMetadata[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const size = DEFAULT_SIZE_PER_PAGE_LIST[0];

  useEffect(() => {
    fetchPersonalizationMetaData(0);
  }, []);

  const fetchPersonalizationMetaData = (currentPage: number) => {
    setLoading(true); 
    dispatch(
      actions.scenarios.learners.fetchLearnerPersonalizedMessagesMetadata(
        { size, page: currentPage },
        id
      )
    )
      .then((response: IListDataResponse<IPersonalizationMessagesMetadata>) => {
        if (response) {
          setPersonalizationMetadata([...personalizationMetadata, ...response.content]);
          setTotal(response.totalElements);
        }
      }).finally(() =>  {
        setLoading(false); 
      });
  };

  const canLoadMoreData = useMemo(() => {
    return personalizationMetadata.length < total;
  }, [total, personalizationMetadata.length]);

  const loadMore = () => {
    if (canLoadMoreData && loading === false) {
      setPage(page + 1);
      fetchPersonalizationMetaData(page + 1);
    }
  };

  const renderItemContent = (data: IPersonalizationMessagesMetadata) => {
    return (
      <>
        <div className={styles.updatedDate}>{moment(data.operatedOn).tz(timezone).format('lll')}</div>
        <div className={styles.updatedContent}>
          <div className={styles.image}>
            <LazyImage
            src={data.operatedBy?.picture}
            fallback={DEFAULT_USER_PROFILE_UPLOAD_ICON_PATH}
            alt={intl.formatMessage({ id: 'MursionPortal.AltText.UserImage' })}
            circle={true}
          />
          </div>
          <div className={styles.text}>{getUserName(data.operatedBy)}</div>
        </div>
      </>
    );
  };

  return (
    <LoadingOverlay active={loading} spinner={true} showFullScreenOverlay={true}>
      <Card className={styles.lastUpdated}>
        <Card.Header className={styles.lastUpdatedClose}><button 
        aria-label={intl.formatMessage({ id: 'MursionPortal.Close' })} onClick={onClose}><img src={closeIcon} alt={intl.formatMessage({ id: 'MursionPortal.Close' })} /></button></Card.Header>
        <Card.Body className={styles.lastUpdatedUsers}>
        {personalizationMetadata.length ? (
          <InfiniteScroll initialLoad={false} loadMore={loadMore} useWindow={false} hasMore={canLoadMoreData}>
          {personalizationMetadata.map((data: IPersonalizationMessagesMetadata, index: number) => {
            return (
              <ListItem key={`${data.operatedBy.email}-${index}`} className={styles.lastUpdatedList}>
                {renderItemContent(data)}
              </ListItem>
            );
          })}
          </InfiniteScroll>
          ) : (
          <ListItem className={styles.lastUpdatedList}>
            {intl.formatMessage({ id: 'MursionPortal.Personalized.MetaData.NoHistory' })}
          </ListItem>
          )}
        </Card.Body>
      </Card>
    </LoadingOverlay>
  );
};
export default PersonalizationMessagesMetadata;
